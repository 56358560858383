import { Uuid } from "@/utils/types"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
  getEnrollmentTimePeriods,
  getSessionStats,
  resetShoppingSession,
} from "../BenefitsElection/benefitsElectionEndpoints"
import { EnrollmentTimePeriodId } from "../BenefitsElection/benefitsElectionTypes"
import {
  checkByopAvailability,
  checkQleAvailability,
  createByopEtp,
  createQle,
  getAutoPayCutOffDate,
  getDashboardWidgetData,
  getEnrollmentReportCsv,
  getShoppingSessionsByCompanyAndEmploymentId,
} from "./dashboardEndpoints"
import { QlePayload } from "./dashboardTypes"

const WIDGET_REFETCH_INTERVAL = 5 * 60 * 1000

export const useGetDashboardWidgetData = (companyId: string) =>
  useQuery({
    queryKey: ["dashboardWidgetData", companyId],
    queryFn: () => getDashboardWidgetData(companyId),
    refetchIntervalInBackground: true,
    refetchInterval: WIDGET_REFETCH_INTERVAL,
  })

export const useGetEnrollmentReportCsv = (companyId: string, planYear: number) =>
  useQuery({
    queryKey: ["companies", companyId, "enrollment-report", planYear],
    queryFn: () => getEnrollmentReportCsv(companyId, planYear),
    // ensure the user needs to click the button to get the latest data
    enabled: false,
  })

export const useGetShoppingSessionsByCompanyAndEmploymentId = (companyId: string, employmentId: string) =>
  useQuery({
    queryKey: ["companies", companyId, "employmentId", employmentId],
    queryFn: () => getShoppingSessionsByCompanyAndEmploymentId(companyId, employmentId),
    refetchOnWindowFocus: false,
    enabled: Boolean(companyId && employmentId),
  })

export const useGetEnrollmentTimePeriodsValue = (employeeId?: string) =>
  useQuery({
    queryKey: ["enrollmentTimePeriods", employeeId],
    queryFn: () => getEnrollmentTimePeriods(employeeId!),
    refetchOnWindowFocus: false,
    enabled: !!employeeId,
  })

export const useGetSessionStats = (planYear: number, companyId?: string) =>
  useQuery({
    queryKey: ["sessionStats", companyId],
    queryFn: () => getSessionStats(companyId!, planYear),
    refetchIntervalInBackground: true,
    enabled: !!companyId,
    refetchInterval: WIDGET_REFETCH_INTERVAL,
  })

export const useCreateQle = () =>
  useMutation({
    mutationFn: (payload: QlePayload) => createQle(payload),
  })

export const useCheckQleAvailability = (employeeId: string) =>
  useQuery({
    queryKey: ["qleAvailability", employeeId],
    queryFn: () => checkQleAvailability(employeeId),
    refetchOnWindowFocus: true,
  })

export const useGetAutoPayCutOffDate = (companyId: string, planId: string) =>
  useQuery({
    queryKey: ["autoPayCutOffDate", companyId, planId],
    queryFn: () => getAutoPayCutOffDate(companyId, planId),
  })

export const useResetShoppingSession = (employmentId: Uuid, shoppingSessionId: Uuid) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (values: EnrollmentTimePeriodId) => resetShoppingSession(employmentId, values),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["shopping-sessions", shoppingSessionId],
      })
    },
  })
}

export const useCreateByopEtp = () =>
  useMutation({
    mutationFn: (employeeId: string) => createByopEtp(employeeId),
  })

export const useCheckByopAvailability = (employeeId: string) =>
  useQuery({
    queryKey: ["byopAvailability", employeeId],
    queryFn: () => checkByopAvailability(employeeId),
    refetchOnWindowFocus: true,
    enabled: !!employeeId,
  })
