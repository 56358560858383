import { AppContentWrapper } from "@/components/AppContentWrapper"
import { Footer } from "@/components/Footer"
import { Navbar } from "@/components/Navbar/Navbar"
import { RootWrapper } from "@/components/RootWrapper"
import styled from "@emotion/styled"
import { Paper as MuiPaper, useMediaQuery } from "@mui/material"
import { spacing } from "@mui/system"
import { ReactNode } from "react"
import { Outlet } from "react-router-dom"
import { CreateCompanyProvider } from "../CreateCompanyProvider"

const Paper = styled(MuiPaper)(spacing)

const MainContent = styled(Paper)`
  color: black;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`

export const PurchaseHraLayout = ({ children }: { children: ReactNode }) => {
  const isLgUp = useMediaQuery(theme => theme.breakpoints.up("sm"))

  return (
    <RootWrapper>
      <AppContentWrapper>
        <Navbar title="ICHRA Plan Setup" />
        <MainContent px={isLgUp ? 30 : 15} py={25}>
          <CreateCompanyProvider>{children}</CreateCompanyProvider>
          <Outlet />
        </MainContent>
        <Footer />
      </AppContentWrapper>
    </RootWrapper>
  )
}
