import { MAXIMUM_CURVE, MINIMUM_CURVE_CHILD } from "@/agecurve"
import { MAX_CHAR_COUNT, NO, YES } from "@/constants"
import { capitalize, isString, round, trimStart } from "lodash"
import { DateTime } from "luxon"
import moment, { Moment } from "moment"
import { Cents, Dollars } from "./types"

// Custom String Types
export type SingleCapitalized<T extends Readonly<string> = Readonly<string>> = T & {
  readonly __tag: unique symbol
}

export type Titlecase<T extends Readonly<string> = Readonly<string>> = T & {
  readonly __tag: unique symbol
}

// Phone Number formatting
export const getDisplayPhoneNumber = (phoneNumber: string): string => {
  if (!/\+\d{10}/.test(phoneNumber)) {
    return phoneNumber
  }

  let displayPhoneNumber = "+X (XXX) XXX-XXXX"

  for (let i = 1; i < phoneNumber.length; i += 1) {
    displayPhoneNumber = displayPhoneNumber.replace("X", phoneNumber[i])
  }

  return displayPhoneNumber
}

export const ensureUsPhoneNumber = (phone: string) => {
  phone = phone.replace(/\D/g, "")
  if (phone.startsWith("+1")) {
    return phone
  }
  if (!phone) {
    return phone
  }

  return `+1${phone}`
}

export const removeCommas = (value: string) => value.replace(/,/g, "")

export const cleanUpUsPhoneNumber = (phone: string) => {
  if (!phone?.length) return ""
  if (phone.startsWith("+1")) {
    phone = phone.substr(2)
  }
  phone = phone.replace(/\D/g, "")

  return phone
}

export const cleanUpNumber = (numstr: string): number => Number(numstr.replace(/\D/g, ""))

// Currency formatting
const formatCurrency = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const formatCurrencyFlat = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export const formatDollars = (amount: Dollars) => formatCurrency.format(amount)
export const formatDollarsFlat = (amount?: Dollars) => (amount ? formatCurrencyFlat.format(amount) : 0)
export const formatCents = (amount: Cents | undefined) => {
  if (amount === undefined) {
    return ""
  }

  return formatDollars(amount / 100)
}
export const formatCentsFlat = (amount: Cents) => formatDollarsFlat(amount / 100)
export const formatCentsWithDecimals = (amount: Cents) => formatDollars(amount / 100)

export const formatDollarToCents = (amount?: string) => {
  if (!amount) {
    return 0
  }
  const numericValue = amount.replace(/[^0-9.]/g, "")
  const floatValue = parseFloat(numericValue)
  const cents = Math.round(floatValue * 100)

  return cents
}

export const formatDollarsToCents = (amount: number) => Math.round(amount * 100)

// Age formatting
export const getDistinctAgeCurveLabel = (age: number) => {
  if (age <= MINIMUM_CURVE_CHILD.age) return "0 - 15"
  if (age >= MAXIMUM_CURVE.age) return "64+"

  return `${age}`
}

// Date formatting
export const DATE_FORMAT_MONTH_YEAR = "DD/YYYY"
export const DATE_FORMAT_MONTH_DAY_YEAR = "MM/DD/YYYY"

export const formatFullMonthNameAndYear = (date: string): string => {
  const dateObj = new Date(date)
  const options: Intl.DateTimeFormatOptions = { month: "long", year: "numeric" }

  return new Intl.DateTimeFormat("en-US", options).format(dateObj)
}

export const transformDate = (date: Date | Moment | string, dateFormat = "YYYY-MM-DD") => {
  if (!date) return "-"
  return moment(date).format(dateFormat)
}

export const getSafeString = (original: string) => original.toLowerCase().replace(/\s/g, "-")

export const formatDate = <T extends Date | null | undefined, R extends T extends Date ? string : T>(date: T) =>
  (date && DateTime.fromJSDate(date).toFormat("MM/dd/yyyy")) as R

export const isTextValidDate = (text: string, dateFormat: string) => moment(text, dateFormat, true).isValid()

export const createDateFromText = <T extends string | null | undefined, R extends T extends string ? Date : T>(
  text: T,
  dateFormat = "YYYY-MM-DD"
) => (text && moment(text, dateFormat, true).toDate()) as R

export const createDateFromDateTimeText = (text: string, dateFormat = "YYYY-MM-DDTHH:mm:ssZ") =>
  moment(text, dateFormat, true).toDate()

export const createDateFromTextOrElse = <T>(
  text: string | null | undefined,
  defaultValue: T,
  dateFormat = "YYYY-MM-DD"
) => (text ? createDateFromText(text, dateFormat) : defaultValue)

// String formatting

/**
 * Capitalizes the first letter of each word in the string
 * @param text to be formatted
 * @param delimiter to split words at
 * @param separator to joing words with
 * @returns formatted text
 */
export const toTitleCase = <const T extends Readonly<string>>(
  text: T,
  delimiter: string | RegExp = "_",
  separator = " "
) => text?.split(delimiter).map(capitalize).join(separator) as Titlecase<T>

export const lowercaseFirstCharacter = (str: unknown) =>
  isString(str) && str.length > 0 ? str[0].toLowerCase() + str.slice(1) : ""

export interface CleanTextOptions {
  lettersOnly?: boolean
  numbersOnly?: boolean
  personNameCharactersOnly?: boolean
  lowerCaseOnly?: boolean
  maxCharacters?: number
}

// allow accents and names like "O'Neil" or "McDonald"
const PERSON_NAMES_REGEX = /[^a-zA-Z\u00C0-\u017F\-'\s]/g
export const cleanText = (
  text: string,
  {
    lettersOnly = false,
    numbersOnly = false,
    lowerCaseOnly = false,
    maxCharacters = MAX_CHAR_COUNT,
    personNameCharactersOnly = false,
  }: CleanTextOptions
) => {
  let cleanTextInput = trimStart(text).replace(/\s+/g, " ")
  if (personNameCharactersOnly) {
    cleanTextInput = cleanTextInput.replace(PERSON_NAMES_REGEX, "")
  }
  if (lettersOnly) {
    cleanTextInput = cleanTextInput.replace(/[^a-zA-Z ]/g, "")
  } else if (numbersOnly) {
    cleanTextInput = cleanTextInput.replace(/\D/g, "")
  }
  if (lowerCaseOnly) {
    cleanTextInput = cleanTextInput.toLowerCase()
  }
  if (cleanTextInput.length > maxCharacters) {
    cleanTextInput = cleanTextInput.slice(0, maxCharacters)
  }

  return trimStart(cleanTextInput)
}

export const trimDecimals = (num: number) => round(num, 2)

export const formatBytes = (bytes: number): string => {
  const units = ["B", "KB", "MB"]
  let value = bytes
  let unitIndex = 0

  while (value >= 1000 && unitIndex < units.length - 1) {
    value /= 1000
    unitIndex = unitIndex + 1
  }

  return `${trimDecimals(value)}${units[unitIndex]}`
}

export const formatBooleanAsYesNo = (value: boolean) => toTitleCase(value ? YES : NO)
