import MarkPro from "@/assets/fonts/Mark-Pro.otf"
import MarkProBold from "@/assets/fonts/MarkPro-Bold.otf"
import MarkProMedium from "@/assets/fonts/MarkPro-Medium.otf"
import TiemposBold from "@/assets/fonts/TiemposHeadline-Bold.otf"
import TiemposMedium from "@/assets/fonts/TiemposHeadline-Medium.otf"
import Tiempos from "@/assets/fonts/TiemposHeadline-Regular.otf"
import TiemposSemibold from "@/assets/fonts/TiemposHeadline-Semibold.otf"
import { Components } from "@mui/material"
import { Theme } from "@mui/system"
import { colors, takeCommandPrimary } from "./palette"
import { variants } from "./variants"

export const components: Components<Theme> = {
  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: "Mark Pro";
        src: local(Mark-Pro), url(${MarkPro}) format("opentype");
      }
      @font-face {
        font-family: "Mark Pro Medium";
        src: local(Mark-Pro), url(${MarkProMedium}) format("opentype");
      }
      @font-face {
        font-family: "Mark Pro Bold";
        src: local(Mark-Pro), url(${MarkProBold}) format("opentype");
      }
      @font-face {
        font-family: "Tiempos Headline";
        src: local(TiemposHeadline-Regular), url(${Tiempos}) format("opentype");
      }
      @font-face {
        font-family: "Tiempos Medium";
        src: local(TiemposHeadline-Medium), url(${TiemposMedium}) format("opentype");
      }
      @font-face {
        font-family: "Tiempos Semibold";
        src: local(TiemposHeadline-Semibold), url(${TiemposSemibold}) format("opentype");
      }
      @font-face {
        font-family: "Tiempos Bold";
        src: local(TiemposHeadline-Bold), url(${TiemposBold}) format("opentype");
      }
    `,
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        fontSize: "1rem",
        fontWeight: 400,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        color: colors.lightGrayText,
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        "&.MuiButton-root.MuiButton-contained": {
          borderRadius: ".75rem",
          boxShadow: "none",
        },
        "&.MuiButton-root.MuiButton-outlined": {
          ":hover": {
            backgroundColor: "lightgray",
          },
          borderRadius: ".75rem",
          border: "0.063rem solid",
          backgroundColor: "white",
        },
        borderRadius: ".75rem",
        padding: ".531rem 1rem",
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: ".75rem",
        boxShadow: "0px 2px 4px 0px rgba(55, 55, 55, 0.08)",
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        "& .MuiInputBase-root": {
          borderRadius: ".75rem",
          backgroundColor: "white",
          boxShadow: "0px 2px 4px 0px rgba(55, 55, 55, 0.08)",
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        borderRadius: ".75rem",
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        borderRadius: ".75rem",
      },
      root: {
        borderRadius: ".75rem",
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: "hover",
    },
    styleOverrides: {
      root: {
        ":hover": {
          color: takeCommandPrimary.dark,
        },
      },
    },
  },
  MuiBadge: {
    styleOverrides: {
      badge: {
        background: takeCommandPrimary.main,
        fontSize: "0.69rem",
        height: "1rem",
        width: "1rem",
        minWidth: "1rem",
        bottom: "0.63rem",
        right: "0.25rem",
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: "h6",
      },
    },
    styleOverrides: {
      action: {
        marginTop: "-4px",
        marginRight: "-4px",
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: ".75rem",
        backgroundImage: "none",
        boxShadow: "0px 2px 4px 0px rgba(55, 55, 55, 0.04)",
        border: "1px solid rgba(230, 227, 225, 1)",
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: "none",
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        paddingTop: "1rem",
        paddingRight: "1.5rem",
        paddingLeft: "1.5rem",
        paddingBottom: "0.5rem",

        "& .dialog-close-icon": {
          height: "1.5rem",
          width: "1.5rem",
          margin: ".3rem",
          cursor: "pointer",
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        marginTop: "0.5rem",
        paddingRight: "1.5rem",
        paddingLeft: "1.5rem",
        paddingBottom: "1.5rem",
      },
    },
  },

  MuiSwitch: {
    styleOverrides: {
      root: {
        "& .MuiSwitch-thumb": {
          boxShadow: "0 0 12px 0 rgba(0,0,0,0.08), 0 0 8px 0 rgba(0,0,0,0.12), 0 0 4px 0 rgba(0,0,0,0.38)",
        },
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        width: "1.25rem",
        height: "1.25rem",
        borderRadius: "50%",
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        "&:hover": {
          background: variants[0].palette.colors.lightTealShade,
          color: variants[0].palette.colors.darkBody,
          svg: {
            color: variants[0].palette.colors.darkBody,
          },
        },
        '& [class*="MuiSvgIcon-root"]': {
          width: "1.5rem",
          height: "1.5rem",
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontSize: "1rem",
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      paper: {
        borderRadius: "4px",
        boxShadow:
          "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        borderRadius: "4px",
        boxShadow:
          "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        borderRadius: 0,
        "&.tch-menu-item:hover": {
          backgroundColor: variants[0].menu.hover.background,
          color: variants[0].palette.colors.darkBody,
        },
      },
    },
  },
  MuiPickersPopper: {
    styleOverrides: {
      paper: {
        margin: "4px",
        borderRadius: "4px",
        boxShadow:
          "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
      },
    },
  },
}
