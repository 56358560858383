import { StyledCard } from "@/components/StyledCard"
import { Typographify } from "@/components/Text"
import { takeCommandPrimary } from "@/theme/palette"
import { createDataQa } from "@/utils/dataQa"
import { VoidFn, WrapperProps } from "@/utils/types"
import { coalesce, has } from "@/utils/util"
import { Button, Grid } from "@mui/material"
import { ReactNode } from "react"
import { Link as RouterLink, To } from "react-router-dom"

type ActionContent =
  | {
      label: string
      onClick: VoidFn
      routerLink?: never
      externalLink?: never
      custom?: never
      disabled?: boolean
    }
  | {
      label: string
      onClick?: never
      routerLink: To
      externalLink?: never
      custom?: never
    }
  | {
      label: string
      onClick?: never
      routerLink?: never
      externalLink: string
      custom?: never
    }
  | {
      label?: never
      onClick?: never
      routerLink?: never
      externalLink?: never
      custom: ReactNode
    }

type Action = ActionContent & {
  key: string
}

class InvalidActionType extends Error {
  constructor() {
    super("Invalid action type")
  }
}
interface WidgetCardActionProps {
  action: Action
}

const WidgetCardAction = ({ action }: WidgetCardActionProps) => {
  if (has(action, "custom")) {
    return action.custom
  }

  if (!has(action, "label")) {
    throw new InvalidActionType()
  }

  if (has(action, "onClick")) {
    return (
      <Button variant="contained" onClick={() => action.onClick()} fullWidth disabled={action.disabled}>
        {action.label}
      </Button>
    )
  }

  if (has(action, "routerLink")) {
    return (
      <Button variant="contained" component={RouterLink} to={action.routerLink} fullWidth>
        {action.label}
      </Button>
    )
  }

  if (has(action, "externalLink")) {
    return (
      <Button variant="contained" href={action.externalLink} fullWidth>
        {action.label}
      </Button>
    )
  }

  throw new InvalidActionType()
}

export interface WidgetCardProps extends WrapperProps {
  title?: ReactNode
  description?: ReactNode
  actions?: Action | Action[]
}

export const WidgetCard = ({
  title,
  description,
  children,
  actions,
  sx = {},
  "data-qa": dataQa = createDataQa("widget-card"),
}: WidgetCardProps) => (
  <StyledCard sx={{ backgroundColor: takeCommandPrimary[200], m: 0, ...sx }} data-qa={dataQa}>
    <Grid container spacing={4}>
      {title && (
        <Grid item xs={12} data-qa={createDataQa(dataQa, "title-container")}>
          <Typographify variant="h6">{title}</Typographify>
        </Grid>
      )}
      {description && (
        <Grid item xs={12} data-qa={createDataQa(dataQa, "description-container")}>
          <Typographify>{description}</Typographify>
        </Grid>
      )}
      {children && (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}
      {(actions && coalesce(actions))?.map(a => (
        <Grid item xs={12} key={a.key}>
          <WidgetCardAction action={a} />
        </Grid>
      ))}
    </Grid>
  </StyledCard>
)
