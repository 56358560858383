import { MAX_CARDS_PER_ROW } from "@/constants"
import { createDataQa, DataQa } from "@/utils/dataQa"
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  SxProps,
  Typography,
  TypographyTypeMap,
  useRadioGroup,
} from "@mui/material"
import { ReactNode } from "react"

interface RadioCardProps<V> {
  title: string
  subtitle?: string
  value: V | undefined
  name: string
  handleChange: (field: string, value: V) => void
  dataQa: string
  alwaysShowSubtitles: boolean
  disabled?: boolean
}

export const RadioCard = <V,>({
  handleChange,
  dataQa,
  value,
  name,
  title,
  subtitle,
  alwaysShowSubtitles,
  disabled = false,
}: RadioCardProps<V>) => {
  const radioGroup = useRadioGroup()
  const isSelected = radioGroup?.value === value

  let borderColor = "colors.borderGray"
  if (isSelected) {
    borderColor = disabled ? "colors.borderGray" : "colors.borderGreen"
  }

  let backgroundColor = "colors.lightBlack"
  if (isSelected) {
    backgroundColor = disabled ? "action.disabledBackground" : "colors.lightTealShade"
  }

  return (
    <Box
      onClick={() => {
        handleChange(name, value!)
      }}
      data-qa={dataQa}
      sx={{
        color: disabled ? "gray" : undefined,
        border: "1px solid",
        borderColor,
        borderRadius: ".75rem",
        backgroundColor,
        cursor: disabled ? "not-allowed" : "pointer",
        minHeight: "4.5rem",
        pl: 4,
        pr: isSelected ? 2 : 4,
        py: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <Box>
        <Typography variant="body1"> {title}</Typography>
        {subtitle && (alwaysShowSubtitles || (!alwaysShowSubtitles && isSelected)) && (
          <Typography variant="caption" sx={{ display: "block", lineHeight: "1.162rem" }}>
            {subtitle}
          </Typography>
        )}
      </Box>

      {isSelected && <Radio color="primary" checked data-qa={dataQa + "button"} size="small" disabled={disabled} />}
    </Box>
  )
}

export interface RadioGroupElement<V> {
  title: string
  subtitle?: string
  value: V | undefined
}

interface ReactGroupCardProps<V> {
  elements: ReadonlyArray<RadioGroupElement<V>>
  name: string
  formName: string
  value?: V
  required?: boolean
  label?: ReactNode
  caption?: string
  labelVariant?: TypographyTypeMap["props"]["variant"]
  alwaysShowSubtitles?: boolean
  maxCardsPerRow?: number
  disabled?: boolean
  "data-qa"?: DataQa
  sx?: SxProps
  handleChange: (field: string, value: V) => void
}

// FUTURE: This component needs to be refactored to support the `YES_NO_OPTION_BOOLEAN` array, which uses
// `true` or `false` values instead of the `YES_NO_OPTIONS` array, which uses "YES" and "NO" strings. The
// `YES_NO_OPTIONS` array is being deprecated, so we need to update this component to support the new array.
export const RadioGroupCard = <V,>({
  formName,
  name,
  elements,
  value,
  required = false,
  label,
  caption,
  labelVariant = "body1bold",
  alwaysShowSubtitles = true,
  maxCardsPerRow = MAX_CARDS_PER_ROW,
  disabled = false,
  "data-qa": dataQa,
  sx = {},
  handleChange,
}: ReactGroupCardProps<V>) => {
  const baseDataQa = dataQa ?? createDataQa(formName, name)
  const spacePerCard = elements.length <= maxCardsPerRow ? 12 / elements.length : 12 / maxCardsPerRow
  const labelDataQa = createDataQa(baseDataQa, "label")

  return (
    <Grid item xs={12} sx={sx}>
      <FormControl component="fieldset" fullWidth required={required}>
        {label && (
          <Typography variant={labelVariant} mt={4} mb={caption ? 0 : 2} data-qa={labelDataQa}>
            <FormLabel
              id={labelDataQa}
              sx={{
                fontSize: "inherit",
                fontFamily: "inherit",
                fontWeight: "inherit",
                color: "inherit",
              }}
            >
              {label}
            </FormLabel>
          </Typography>
        )}

        {caption && (
          <Typography variant="caption" mb={2}>
            {caption}
          </Typography>
        )}

        <RadioGroup name={name} value={value} {...(label && { "aria-labelledby": labelDataQa })}>
          <Grid container data-qa={baseDataQa} spacing={4}>
            {elements.map(element => (
              <Grid item xs={12} sm={6} md={spacePerCard} key={String(element.value)}>
                <RadioCard<V>
                  title={element.title}
                  value={element.value}
                  subtitle={element.subtitle}
                  name={name}
                  handleChange={handleChange}
                  dataQa={createDataQa(dataQa ?? formName, String(element.value), "radioCard")}
                  alwaysShowSubtitles={alwaysShowSubtitles}
                  disabled={disabled}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </FormControl>
    </Grid>
  )
}
