import { ClearableSearchInput } from "@/components/ClearableSearchInput/ClearableSearchInput"
import { FilterDropdownField } from "@/components/FilterDropdownField"
import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { BaseTable } from "@/components/Table/Table"
import { TABLE_CELL_PADDING } from "@/constants"
import { ACCOUNT } from "@/features/CreateCompany/createCompanyConstants"
import { useAutoPayCompaniesInfo, useFundingEvents } from "@/features/TCHub/tcHubService"
import { FundingEventModel, FundingEventsTableHeader, MigratedCompanySetDetails } from "@/features/TCHub/tcHubTypes"
import { DATE_FORMAT_MONTH_DAY_YEAR, toTitleCase, transformDate } from "@/utils/formatting"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import { Button, Grid, TableCell, Typography, useMediaQuery, useTheme } from "@mui/material"
import { constant } from "lodash"
import { useMemo, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import { TcHubGuard } from "../../../Auth/guards/TcHubGuard"
import { CompanyModel, CompanyOnboardingStatus } from "../../../CreateCompany/createCompanyEndpoints"
import { AddNewFundingEventModal } from "./AddNewFundingEventModal"
import {
  getFundingEventStatus,
  getFundingEventTimePeriod,
  useFundingEventsPageParams,
} from "./fundingEventDetailsService"

const MIGRATED_COMPANY_IGNORE_RECURRING_EVENTS_YEAR = 2024

const getOnboardingStatusValue = (onboardingStatus: CompanyOnboardingStatus[] | undefined, key: string) => {
  if (!onboardingStatus || onboardingStatus.length === 0) {
    return false
  }
  const accountStep = onboardingStatus.find(status => status.step === ACCOUNT)
  if (accountStep?.statusValue) {
    try {
      const statusValue = JSON.parse(accountStep.statusValue)
      return statusValue[key] || false
    } catch (e) {
      console.error("Failed to parse statusValue:", e)
      return false
    }
  }
}

const headers: FundingEventsTableHeader[] = [
  { id: "companyName", label: "Company Name", sortable: true, field: "companyName", alignment: "left" },
  {
    id: "timePeriod",
    label: "Time Period",
    sortable: true,
    field: "timePeriod",
    alignment: "left",
  },
  {
    id: "timePeriodLabel",
    label: "Description",
    sortable: true,
    field: "timePeriodLabel",
    alignment: "left",
  },
  {
    id: "periodStartAt",
    label: "Start Date",
    sortable: true,
    field: "periodStartAt",
    alignment: "left",
  },
  {
    id: "periodEndAt",
    label: "End Date",
    sortable: true,
    field: "periodEndAt",
    alignment: "left",
  },
  {
    id: "status",
    label: "Status",
    sortable: true,
    field: "status",
    alignment: "left",
  },
]

export const CompanySet = (companies: CompanyModel[]) => {
  const companySet = new Map<string, MigratedCompanySetDetails>()

  companies.forEach(company => {
    companySet.set(company.id, {
      companyName: company.companyInfo.companyName,
      isMigrated: getOnboardingStatusValue(company.companyOnboardingStatus, "isMigrated"),
    })
  })

  return companySet
}

const matchAccountName = (fundingEvent: FundingEventModel, searchQueryLowerCase: string) =>
  (fundingEvent.name ?? "").toLowerCase().includes(searchQueryLowerCase)

const matchCompanyName = (
  fundingEvent: FundingEventModel,
  companyId: string,
  companySet: Map<string, MigratedCompanySetDetails>,
  searchQueryLowerCase: string
) => companySet.get(companyId)?.companyName?.toLowerCase().includes(searchQueryLowerCase)

export const TcHubFundingEventsPage = () => {
  const materialTheme = useTheme()
  const isMobile = useMediaQuery(materialTheme.breakpoints.down("sm"))
  const navigate = useNavigate()
  const [searchInputValue, setSearchInputValue] = useState<string>("")
  const [showNewFundingEventModal, setShowNewFundingEventModal] = useState(false)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(25)
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc")
  const [sortBy, setSortBy] = useState("periodEndAt")

  const toggleSortBy = (headId: string) => {
    if (sortBy === headId) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc")
    } else {
      setSortBy(headId)
      setSortDirection("asc")
    }
  }

  const { fundingEventStatus, setFundingEventStatus, fundingEventTimePeriod, setFundingEventTimePeriod } =
    useFundingEventsPageParams()
  const { data: fundingEvents, isLoading: isFundingEventsLoading } = useFundingEvents()
  const { data: companies, isLoading: isCompaniesLoading } = useAutoPayCompaniesInfo()

  const fundingEventTimePeriodOptions = getFundingEventTimePeriod(fundingEvents)
  const fundingEventStatusOptions = getFundingEventStatus(fundingEvents)

  const companySet = useMemo(() => CompanySet(companies ?? []), [companies])

  const filteredFundingEvents = useMemo(
    () =>
      (fundingEvents ?? [])
        .filter((fundingEvent: FundingEventModel) => {
          const searchQueryLowerCase = searchInputValue.toLowerCase()
          const periodEndYear = new Date(fundingEvent.periodEndAt).getFullYear()
          const isMigrated = companySet.get(fundingEvent.companyId)?.isMigrated ?? false

          // Filter out 2024 recurring events for migrated companies
          if (
            periodEndYear === MIGRATED_COMPANY_IGNORE_RECURRING_EVENTS_YEAR &&
            fundingEvent.timePeriod === "RECURRING" &&
            isMigrated
          ) {
            return false
          }

          return (
            matchAccountName(fundingEvent, searchQueryLowerCase) ||
            matchCompanyName(fundingEvent, fundingEvent.companyId, companySet, searchQueryLowerCase)
          )
        })
        .filter(
          fundingEvent =>
            (fundingEvent.status === fundingEventStatus || !fundingEventStatus) &&
            (fundingEvent.timePeriod === fundingEventTimePeriod || !fundingEventTimePeriod)
        )
        .map(fundingEvent => ({
          ...fundingEvent,
          companyName: companySet.get(fundingEvent.companyId)?.companyName ?? "",
        })),
    [fundingEvents, searchInputValue, companySet, fundingEventStatus, fundingEventTimePeriod]
  )
  const closeFundingEventModal = () => {
    setShowNewFundingEventModal(false)
  }

  const openFundingEventModal = () => {
    setShowNewFundingEventModal(true)
  }

  return (
    <TcHubGuard requiredPermissions={["tc_hub_autopay"]}>
      <Grid container data-qa="tc-hub-funding-events-page">
        <Helmet title="TC Hub Funding Events" />
        <Grid item xs={12} mb={5}>
          <Typography variant="h1" display="inline" data-qa="funding-events">
            Funding Events
          </Typography>
        </Grid>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Grid
              container
              alignItems="center"
              justifyContent="flex-start"
              spacing={3}
              sx={{ mb: isMobile ? 3 : null }}
            >
              <Grid item>
                <ClearableSearchInput
                  onChange={event => setSearchInputValue(event.target.value)}
                  handleClearClick={() => setSearchInputValue("")}
                  data-qa="funding-events-search-input"
                />
              </Grid>
              <Grid item>
                <FilterDropdownField
                  options={fundingEventTimePeriodOptions}
                  value={fundingEventTimePeriod}
                  onChange={setFundingEventTimePeriod}
                  label="Time Period"
                  data-qa="time-period"
                />
              </Grid>
              <Grid item sx={{ mb: isMobile ? 2 : null }}>
                <FilterDropdownField
                  options={fundingEventStatusOptions}
                  value={fundingEventStatus}
                  onChange={setFundingEventStatus}
                  label="Status"
                  data-qa="status"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={openFundingEventModal} startIcon={<AddOutlinedIcon />}>
              Add new funding event
            </Button>
          </Grid>
          <AddNewFundingEventModal
            onClose={closeFundingEventModal}
            showNewFundingEventModal={showNewFundingEventModal}
          />
        </Grid>
        {!isCompaniesLoading || !isFundingEventsLoading ? (
          <BaseTable
            rows={filteredFundingEvents}
            selected={[]}
            searchCriteria=""
            onToggleSelect={() => []}
            onToggleOrderBy={headId => toggleSortBy(headId)}
            onToggleSelectAll={() => []}
            onPageChange={changedPage => setPage(changedPage)}
            onRowsPerPageChange={rows => setRowsPerPage(rows)}
            uniqueIdSelector={constant("")}
            headCells={headers ?? []}
            rowsPerPage={rowsPerPage}
            page={page}
            fullWidth
            orderBy={[{ headCellId: sortBy, direction: sortDirection }]}
            onRowClick={(row: FundingEventModel) => {
              navigate(`${row.companyId}/${row.id}`)
            }}
            exportCsv
            csvTitle="Funding Events"
          >
            {({ row }) => (
              <>
                <TableCell>
                  <Typography variant="body1" sx={TABLE_CELL_PADDING} data-qa="funding-entity-name">
                    {row.companyName}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={TABLE_CELL_PADDING} data-qa="time-period">
                    {toTitleCase(row.timePeriod)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={TABLE_CELL_PADDING} data-qa="time-period-label">
                    {row.timePeriodLabel}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                    {transformDate(row.periodStartAt, DATE_FORMAT_MONTH_DAY_YEAR)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                    {transformDate(row.periodEndAt, DATE_FORMAT_MONTH_DAY_YEAR)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                    {toTitleCase(row.status)}
                  </Typography>
                </TableCell>
              </>
            )}
          </BaseTable>
        ) : (
          <SkeletonTableLoader
            data-qa="skeleton-table-loader-funding-events"
            headerTitles={headers.map(column => `${column.label}`)}
            bodyRowsCount={10}
          />
        )}
      </Grid>
    </TcHubGuard>
  )
}
