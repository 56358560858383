import { StyledCard } from "@/components/StyledCard"
import { DisbursementAccountDetails } from "@/features/Funding/fundingTypes"
import { useRecurringPremiums } from "@/features/TCHub/tcHubService"
import { formatCents } from "@/utils/formatting"
import { CardContent, CardHeader, Grid, Typography } from "@mui/material"
import { format } from "date-fns"
import { calculateMinimumReserveAmount } from "../autopayUtils"
import { SkeletonSnapshotLoader } from "./SkeletonSnapshotLoader"

interface DisbursementAccountSnapshotProps {
  disbursementAccountDetails: DisbursementAccountDetails
}

export const DisbursementAccountSnapshot = ({ disbursementAccountDetails }: DisbursementAccountSnapshotProps) => {
  const totalBalance = disbursementAccountDetails?.accountBalance + disbursementAccountDetails?.accountBalanceOnHold

  const { data: recurringPremiumCollection } = useRecurringPremiums(disbursementAccountDetails?.companyId ?? "", {
    companyId: disbursementAccountDetails?.companyId,
    coverageDate: format(
      new Date(
        new Date().getMonth() === 11 ? new Date().getFullYear() + 1 : new Date().getFullYear(),
        (new Date().getMonth() + 2) % 12,
        1
      ),
      "yyyy-MM-dd"
    ),
    status: "APPROVED",
    isPrimary: true,
    isReimbursement: false,
    limit: 1000,
    offset: 0,
  })

  if (!disbursementAccountDetails) {
    return <SkeletonSnapshotLoader />
  }

  return (
    <StyledCard sx={{ p: 2 }}>
      <CardHeader
        title={<Typography variant="h5">Disbursement Account</Typography>}
        action={<Typography variant="caption">****{disbursementAccountDetails?.accountLast4Digits}</Typography>}
      />
      <CardContent>
        <Grid
          container
          direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="body1" sx={{ pb: 1.5 }}>
              Balance
            </Typography>
            <Typography variant="h5" align="left">
              {totalBalance !== undefined ? formatCents(totalBalance) : "—"}
            </Typography>
          </Grid>
          <Typography sx={{ pb: 4, pt: 4, pl: 4, pr: 4, color: "colors.silverSand" }} fontWeight="bold">
            FOR
          </Typography>
          <Grid item>
            <Typography variant="body1" sx={{ pb: 1.5 }}>
              Available
            </Typography>
            <Typography variant="h5" align="left">
              {disbursementAccountDetails?.accountBalance !== undefined
                ? formatCents(disbursementAccountDetails?.accountBalance)
                : "—"}
            </Typography>
          </Grid>
          <Typography sx={{ pb: 4, pt: 4, pl: 4, pr: 4, color: "colors.silverSand" }} fontWeight="bold">
            ON
          </Typography>
          <Grid item>
            <Typography variant="body1" sx={{ pb: 1.5 }}>
              Minimum Reserve
            </Typography>
            <Typography variant="h5" align="left">
              {calculateMinimumReserveAmount(recurringPremiumCollection?.recurringPremiums) !== undefined
                ? formatCents(calculateMinimumReserveAmount(recurringPremiumCollection?.recurringPremiums))
                : "—"}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  )
}
