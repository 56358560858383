import { ConfirmationModal } from "@/components/ConfirmationModal"
import { getAdminDocDownloadUrl } from "@/features/Documents/documentsEndpoints"
import { usePollingTaskStatus } from "@/features/Tasks/taskManagementService"
import { TASK_STATUS_COMPLETED, TASK_STATUS_FAILED } from "@/features/TCHub/tcHubConstants"
import { createApplicationCsvDownloadRequest } from "@/features/TCHub/tcHubEndpoints"
import { useNotifications } from "@/services/notificationService"
import { sleep } from "@/utils/useAsyncRetry"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { Alert, Button, Grid, Menu, MenuItem, PopoverProps, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { useEffect, useState } from "react"

const MAX_EXPORT_WAIT_DURATION_IN_SECONDS = 60

export interface ExportEnrollmentToCsvButtonProps {
  ids: string[]
  disabled: boolean
}

const pluralize = (word: string, amount: number) => `${amount} ${word}${amount > 1 ? "s" : ""}`

const TimerUntil = ({
  startedAt,
  finishedAt,
  timeoutMessage,
  waitMessage,
  onTimeout,
}: {
  startedAt: string
  finishedAt: string
  timeoutMessage: string | React.ReactNode
  waitMessage: string | React.ReactNode
  onTimeout?: () => void
}) => {
  const [seconds, setSeconds] = useState(DateTime.fromISO(finishedAt).diff(DateTime.fromISO(startedAt)).as("seconds"))
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(draft => {
        if (draft <= 1) {
          clearInterval(interval)
          onTimeout?.()
          return 0
        }
        return draft - 1
      })
    }, 1000)
    return () => clearInterval(interval)
  })
  const minutes = Math.floor(seconds / 60)
  if (seconds <= 0) {
    return timeoutMessage
  }
  if (seconds > 60) {
    return (
      <>
        {waitMessage} {pluralize("minute", minutes)} left
      </>
    )
  }
  return (
    <>
      {waitMessage} {pluralize("second", seconds)} left
    </>
  )
}

interface ExportRequest {
  minimalFieldsOnly: boolean
  extendedFields: boolean
  escapeQuotes: boolean
  escapeApostrophe: boolean
}

const WaitingModalContent = ({
  numSubmitted,
  startedExportAt,
  onTimeout,
}: {
  numSubmitted: number
  startedExportAt: string | null
  onTimeout: () => void
}) => (
  <Grid>
    <Typography variant="body1">
      Exporting {pluralize("record", numSubmitted)}.
      {startedExportAt && (
        <TimerUntil
          startedAt={startedExportAt}
          finishedAt={DateTime.fromISO(startedExportAt).plus({ seconds: MAX_EXPORT_WAIT_DURATION_IN_SECONDS }).toISO()!}
          timeoutMessage=""
          waitMessage=" "
          onTimeout={onTimeout}
        />
      )}
      .
    </Typography>
  </Grid>
)

export const ExportEnrollmentToCsvButton = ({ ids, disabled }: ExportEnrollmentToCsvButtonProps) => {
  const [downloadLink, setDownloadLink] = useState("")
  const [numSubmitted, setNumSubmitted] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [exportState, setExportState] = useState({
    startedExportAt: null as string | null,
    popoverRef: null as PopoverProps["anchorEl"] | null,
    taskId: null as string | null,
    isExporting: false,
    didExportTimedOut: false,
    lastExport: null as ExportRequest | null,
  })
  const clearExportState = () =>
    setExportState({
      startedExportAt: null,
      popoverRef: null,
      taskId: null,
      isExporting: false,
      didExportTimedOut: false,
      lastExport: null,
    })

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const { notify } = useNotifications("csv-application-download")

  const { task, error, isPolling, startPolling, stopPolling } = usePollingTaskStatus(3000)

  useEffect(() => {
    if (!isModalOpen && isPolling) {
      stopPolling()
      clearExportState()
    }
  }, [isModalOpen, isPolling, stopPolling])

  const startExport =
    ({ minimalFieldsOnly, extendedFields, escapeQuotes, escapeApostrophe }: ExportRequest) =>
    async () => {
      const execution = () =>
        createApplicationCsvDownloadRequest({
          healthBenefitElectionIds: ids,
          minimalFieldsOnly,
          extendedFields,
          escapeQuotes,
          escapeApostrophe,
        })
      const currentExportState = {
        popoverRef: null,
        taskId: null,
        isExporting: true,
        startedExportAt: DateTime.now().toISO(),
        didExportTimedOut: false,
        lastExport: { minimalFieldsOnly, extendedFields, escapeQuotes, escapeApostrophe },
      }
      setExportState(currentExportState)
      try {
        setIsModalOpen(true)
        setNumSubmitted(ids.length)
        let response = null
        const maxRetries = 3
        let retryCount = 0
        let responsError = null
        while (retryCount < maxRetries) {
          try {
            response = await execution()
            break
          } catch (err: any) {
            responsError = err
            await sleep(3000 + Math.random() * 2000)
          }
          retryCount += 1
        }
        if (!response || !response.taskId || retryCount >= maxRetries) {
          throw responsError || new Error("Failed to start export. Please try again later.")
        }
        setExportState({ ...currentExportState, taskId: response.taskId })
        startPolling(response.taskId)
      } catch (err) {
        console.error("Failed to start export", err)
        setErrorMessage("Failed to start export. Please try again later.")
      } finally {
        setExportState(state => ({ ...state, isExporting: false }))
      }
    }

  const handleCloseDownloadModal = () => {
    setIsModalOpen(false)
    setDownloadLink("")
    setNumSubmitted(0)
    setErrorMessage(null)
    clearExportState()
  }

  useEffect(() => {
    const getDownloadLink = async (resourceId: string) => {
      try {
        const response = await getAdminDocDownloadUrl(resourceId)
        setDownloadLink(response.url)
      } catch {
        setErrorMessage("Failed to get download link. Please try again later.")
      }
    }

    const handleError = (message: string) => {
      setIsModalOpen(false)
      notify(message, "error")
      clearExportState()
    }

    if (errorMessage) {
      handleError(errorMessage)
      setErrorMessage(null)
    } else if (task?.status === TASK_STATUS_COMPLETED) {
      getDownloadLink(task.resourceId).catch(() =>
        handleError(error || "Failed to get download link. Please try again later.")
      )
    } else if (task?.status === TASK_STATUS_FAILED || error) {
      handleError(error || "Failed to start export. Please try again later.")
    }
  }, [task, error, notify, errorMessage])

  const isProcessing = exportState?.isExporting || isPolling
  const isCompleted = !isProcessing && task?.status === TASK_STATUS_COMPLETED
  return (
    <>
      <Button
        variant="outlined"
        endIcon={<ArrowDropDownIcon />}
        onClick={evt => {
          setExportState({
            popoverRef: evt.currentTarget,
            startedExportAt: null,
            taskId: null,
            isExporting: false,
            didExportTimedOut: false,
            lastExport: null,
          })
        }}
        disabled={disabled || isPolling}
      >
        Export CSV
      </Button>
      <Menu
        anchorEl={exportState?.popoverRef}
        open={Boolean(exportState?.popoverRef)}
        onClose={() => setExportState(state => ({ ...state, popoverRef: null }))}
      >
        <MenuItem
          className="tch-menu-item"
          onClick={startExport({
            minimalFieldsOnly: false,
            extendedFields: true,
            escapeQuotes: true,
            escapeApostrophe: false,
          })}
        >
          Download csv for excel with questions
        </MenuItem>
        <MenuItem
          className="tch-menu-item"
          onClick={startExport({
            minimalFieldsOnly: false,
            extendedFields: false,
            escapeQuotes: true,
            escapeApostrophe: false,
          })}
        >
          Download csv for excel without questions
        </MenuItem>
        <MenuItem
          className="tch-menu-item"
          onClick={startExport({
            minimalFieldsOnly: false,
            extendedFields: true,
            escapeQuotes: false,
            escapeApostrophe: true,
          })}
        >
          Download csv for automation with questions
        </MenuItem>
        <MenuItem
          className="tch-menu-item"
          onClick={startExport({
            minimalFieldsOnly: true,
            extendedFields: false,
            escapeQuotes: false,
            escapeApostrophe: false,
          })}
        >
          Download csv for automation without questions
        </MenuItem>
      </Menu>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="We're getting your file ready"
        actionLabel={exportState?.didExportTimedOut && !isCompleted ? "Retry Export" : "Download"}
        isSubmitting={!exportState?.didExportTimedOut && (isProcessing || !downloadLink)}
        onConfirm={() => {
          if (task?.status === TASK_STATUS_COMPLETED && downloadLink) {
            const link = document.createElement("a")
            link.target = "_blank"
            link.href = downloadLink
            link.download = `application_${Date.now().toLocaleString}.csv`
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            handleCloseDownloadModal()
          } else {
            if (exportState?.didExportTimedOut && exportState?.lastExport) {
              startExport(exportState.lastExport)()
            }
          }
        }}
      >
        {Boolean(exportState?.didExportTimedOut && task?.status !== TASK_STATUS_COMPLETED) && (
          <Alert severity="error">
            <Typography variant="body1">
              Export of {pluralize("record", numSubmitted)} failed. Click Retry Export to try again.
            </Typography>
          </Alert>
        )}
        {Boolean(!isProcessing && task?.status === TASK_STATUS_FAILED) && (
          <Alert severity="error">
            <Typography variant="body1">
              Export of {pluralize("record", numSubmitted)} failed. Please try again.
            </Typography>
          </Alert>
        )}
        {Boolean(!isProcessing && task?.status === TASK_STATUS_COMPLETED) && (
          <Typography variant="body1">{pluralize("record", numSubmitted)} exported.</Typography>
        )}
        {Boolean(!exportState?.didExportTimedOut && isProcessing && task?.status !== TASK_STATUS_COMPLETED) && (
          <WaitingModalContent
            numSubmitted={numSubmitted}
            startedExportAt={exportState?.startedExportAt}
            onTimeout={() => {
              stopPolling()
              setExportState(state => ({
                ...state,
                taskId: null,
                didExportTimedOut: true,
              }))
            }}
          />
        )}
      </ConfirmationModal>
    </>
  )
}
