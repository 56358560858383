import { HraPlanModel } from "@/features/CreateCompany/components/Steps/Setup/PlanSetup/planSetupTypes"
import {
  useGetCurrentClasses,
  useUpdatePlanStructure,
} from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/planStructureService"
import {
  ClassCardKey,
  CustomClassDetails,
} from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/planStructureTypes"
import {
  buildCustomClassesPatchRequest,
  createClass,
} from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/planStructureUtils"
import { useNotifications } from "@/services/notificationService"
import { Uuid } from "@/utils/types"
import { useParams } from "react-router-dom"

// Hook to manage custom classes without using the store
export const useUpdateClasses = (currentHraPlan: HraPlanModel) => {
  const { companyId } = useParams<{ companyId: Uuid }>()
  const { notify } = useNotifications("update-classes")

  const { data: classes } = useGetCurrentClasses(companyId, currentHraPlan.id)
  const hraClasses = classes?.map(createClass) ?? []
  const customClasses = hraClasses?.reduce((a, v) => ({ ...a, [v.classId]: v }), {})

  const { mutateAsync: updatePlanStructure, isPending: isUpdatingClasses } = useUpdatePlanStructure(
    companyId!,
    currentHraPlan.id
  )

  const updateClasses = async (customClass: CustomClassDetails) => {
    const classId = customClass.classId || "localid-" + window.crypto.randomUUID() // Used to mantain consistency with store logic

    const patchRequest = buildCustomClassesPatchRequest({
      customClassData: { [classId]: customClass },
      loadedData: classes,
    })

    if (patchRequest) {
      await updatePlanStructure(patchRequest)
    } else {
      throw new Error(`Patch request body empty for param ${JSON.stringify(customClass)}`)
    }
  }

  const deleteClasses = async (classKeys: ClassCardKey[]) => {
    const classesWithBenefits = classes?.filter(
      customClass => classKeys.includes(customClass.id) && customClass.healthBenefits?.length
    )

    const thereAreClassesWithBenefits = !!classesWithBenefits?.length

    if (thereAreClassesWithBenefits) {
      const namesOfClasses = classesWithBenefits.map(customClass => customClass.name).join(", ")
      const singleClassMessage = "Cannot deleted the selected class because it has health benefits"
      const multipleClassMessage = `Cannot delete the selected classes because the classes ${namesOfClasses} have health benefits.`
      notify(classKeys.length > 1 ? multipleClassMessage : singleClassMessage, "error")
    } else {
      const patchRequest = buildCustomClassesPatchRequest({
        deletedClasses: classKeys,
        loadedData: classes,
      })

      if (patchRequest) {
        await updatePlanStructure(patchRequest)
        const classText = classKeys.length > 1 ? "classes were" : "class was"
        const numberOfClassesMessage = classKeys.length > 1 ? `${classKeys.length} ` : ""
        notify("The selected " + numberOfClassesMessage + classText + " deleted successfully", "success")
      }
    }
  }

  return { customClasses, isUpdatingClasses, updateClasses, deleteClasses }
}
