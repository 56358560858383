import { Paper } from "@mui/material"
import { ReactNode } from "react"

interface MainContentWrapperProps {
  children?: ReactNode
}

export const MainContentWrapper = ({ children }: MainContentWrapperProps) => (
  <Paper
    sx={{
      flex: 1,
      backgroundColor: "colors.regionGray",
      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        flex: "none",
      },
      ".MuiPaper-root .MuiPaper-root": {
        boxShadow: "none",
      },
      p: {
        xs: 5,
        lg: 8,
      },
    }}
  >
    {children}
  </Paper>
)
