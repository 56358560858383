import { Reports } from "./reportsTypes"

export const reports: Reports[] = [
  {
    name: "Payroll Reports",
    description: "AutoPay calculation is based on the expected carrier transactions.",
    buttonText: "Download reports",
  },
  {
    name: "Reconciliation Reports",
    description: "This report reconciles the payroll report with cleared carrier transactions.",
    buttonText: "Download reports",
  },
]

export const PAYROLL_REPORT_NAME = "Payroll Reports"
export const AUTOPAY_RECONCILIATION_REPORT_NAME = "Reconciliation Reports"
export const ENROLLMENT_STATUS_REPORT_NAME = "Enrollment Status"
