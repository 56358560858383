import { appConfig } from "@/config"
import { axiosInstance as axios } from "@/services/axios"
import { Uuid } from "@/utils/types"
import { CompanyListResponse, CompanyModel } from "../CreateCompany/createCompanyEndpoints"
import { PersonModel } from "../People/peopleTypes"
import {
  ApplicationResponse,
  BusinessUnit,
  CreateFundingEventRequest,
  CreateFundingEventResponse,
  CreateRecurringPremiumTcHubRequest,
  FundingEntityDetailModel,
  FundingEntityModel,
  FundingEventsResponse,
  FundingEventSummaryModel,
  FundingEventUpdate,
  FundsTransferRequest,
  FundsTransferResponse,
  HealthBenefitElectionRequest,
  JournalEntryRequest,
  JournalEntryResponse,
  PeopleReponse,
  PersonDetailResponse,
  RecurringPremiumCopyRequest,
  RecurringPremiumDetailModel,
  RecurringPremiumSearchFilters,
  RecurringPremiumSearchResponse,
  RecurringPremiumUpdateRequest,
  RegistrationTrackingDetailModel,
  RegistrationTrackingModel,
} from "./tcHubTypes"
import { getPersonWithFormatDate } from "./TcHubUtils"

const tcHubBaseUrl = appConfig.baseApiUrl
const ledgerBaseUrl = `${appConfig.baseApiUrl}/ledger`
const peopleBaseUrl = `${appConfig.baseApiUrl}`
const benefitsBaseUrl = `${appConfig.baseApiUrl}/benefitelection`

export const getAllActiveCompanies = async (offset: number, limit: number) => {
  const companies = (
    await axios.get<CompanyModel[]>(`${tcHubBaseUrl}/benefits/v1/companies?offset=${offset}&limit=${limit}`)
  ).data

  return companies
}

export const getAutopayCompanies = async ({ latestCreatedAt = "", createdBefore = "", limit = 200 }) => {
  const companyListResponse = (
    await axios.get<CompanyListResponse>(
      `${tcHubBaseUrl}/benefits/v1/autopay-companies?latestCreatedAt=${latestCreatedAt}&createdBefore=${createdBefore}&limit=${limit}`
    )
  ).data

  return companyListResponse
}

export const getAutopayCompaniesInfo = async ({
  latestCreatedAt = "",
  createdBefore = "",
  limit = 1000,
  isAutoPayActiveOnly = false,
}) => {
  const companyListResponse = (
    await axios.post<CompanyListResponse>(`${tcHubBaseUrl}/ledger/v1/autopay-companies`, {
      latestCreatedAt,
      createdBefore,
      limit,
      isAutoPayActiveOnly,
    })
  ).data

  return companyListResponse
}

export const getAllFundingEntities = async () => {
  const fundingEntities = (await axios.get<FundingEntityModel[]>(`${tcHubBaseUrl}/funding/v1/funding-entities`)).data

  return fundingEntities
}

export const getFundingEntityDetails = async (fundingEntityId: string) => {
  const fundingEntityDetails = (
    await axios.get<FundingEntityDetailModel>(`${tcHubBaseUrl}/funding/v1/funding-entities/${fundingEntityId}/details`)
  ).data

  return fundingEntityDetails
}

export const getRegistrationTrackingDetails = async (registrationTrackingId: string) => {
  const registrationTrackingDetails = (
    await axios.get<RegistrationTrackingDetailModel>(
      `${tcHubBaseUrl}/funding/v1/registration-trackings/${registrationTrackingId}`
    )
  ).data

  return registrationTrackingDetails
}

export const getAllRegistrationTracking = async () => {
  const registrationTracking = (
    await axios.get<RegistrationTrackingModel[]>(`${tcHubBaseUrl}/funding/v1/registration-trackings`)
  ).data

  return registrationTracking
}

export const updateRegistrationTracking = async (
  registrationTrackingId: string,
  data: RegistrationTrackingDetailModel
) => (await axios.patch(`${tcHubBaseUrl}/funding/v1/registration-trackings/${registrationTrackingId}`, data)).data

export const getAllFundingEvents = async () => {
  const fundingEvents = (await axios.get<FundingEventsResponse>(`${tcHubBaseUrl}/funding/v1/funding-events`)).data

  return fundingEvents?.companyFundingEvents ?? []
}

export const getFundingEventById = async (companyId: string, fundingEventId: string) => {
  const fundingEvent = (
    await axios.get<FundingEventSummaryModel>(
      `${tcHubBaseUrl}/funding/v1/companies/${companyId}/funding-events/${fundingEventId}/summary`
    )
  ).data

  return fundingEvent
}

export const updateFundingEvent = async (
  companyId: string,
  fundingEventId: string,
  fundingEventUpdate: FundingEventUpdate
) => {
  const response = await axios.patch(
    `${tcHubBaseUrl}/funding/v1/companies/${companyId}/funding-events/${fundingEventId}`,
    fundingEventUpdate
  )

  return response.data
}

export const deleteFundingEvent = async (companyId: string, fundingEventId: string) => {
  const response = await axios.delete(
    `${tcHubBaseUrl}/funding/v1/companies/${companyId}/funding-events/${fundingEventId}`
  )

  return response.data
}

export const processFundingEvent = async (companyId: string, periodStartAt: string, periodEndAt: string) => {
  const response = await axios.post<{
    fundingEventId: string
    status: string
  }>(`${tcHubBaseUrl}/funding/v1/companies/${companyId}/funding-events/process`, {
    periodStartAt,
    periodEndAt,
  })

  return response.data
}

export const createAdHocBookTransfer = async (
  companyId: string,
  data: FundsTransferRequest
): Promise<FundsTransferResponse> => {
  const paymentResponse = (
    await axios.post<FundsTransferResponse>(
      `${tcHubBaseUrl}/funding/v1/companies/${companyId}/create-ad-hoc-book-transfer`,
      data
    )
  ).data

  return paymentResponse
}

export const createJournalEntry = async (data: JournalEntryRequest) =>
  (await axios.post<JournalEntryResponse>(`${ledgerBaseUrl}/v1/journal-entries`, data)).data

export const reverseJournalEntry = async (journalEntryId: Uuid) =>
  (await axios.post<JournalEntryResponse>(`${ledgerBaseUrl}/v1/journal-entries/${journalEntryId}/reverse`, {})).data

export const createAdHocFundingTransfer = async (
  companyId: string,
  data: FundsTransferRequest
): Promise<FundsTransferResponse> => {
  const paymentResponse = (
    await axios.post<FundsTransferResponse>(
      `${tcHubBaseUrl}/funding/v1/companies/${companyId}/create-ad-hoc-funding-transfer`,
      data
    )
  ).data

  return paymentResponse
}

export const searchRecurringPremiums = async (companyId: string, data: RecurringPremiumSearchFilters) => {
  try {
    const response = await axios.post(`${ledgerBaseUrl}/v1/companies/${companyId}/recurring-premiums/search`, {
      ...data,
    })
    return response.data as RecurringPremiumSearchResponse
  } catch (error) {
    console.error("Error fetching the recurring premiums:", error)
    throw error
  }
}

export const getReserveBalanceCents = async (companyId: string) => {
  try {
    const response = await axios.get(`${ledgerBaseUrl}/v1/companies/${companyId}/accounts/reserve-balance`)

    return response.data as { accountCategory: string; accountId: string; balanceCents: number }
  } catch (error) {
    console.error("Error fetching the company reserve amount:", error)
    throw error
  }
}

export const getAllowanceBalanceCentsByEmploymentId = async (employmentId: string) => {
  try {
    const response = await axios.get(`${ledgerBaseUrl}/v1/employment/${employmentId}/accounts/allowance-balance`)
    return response.data as Array<{
      accountCategory: string
      accountId: string
      balanceCents: number
      planYear: number
    }>
  } catch (error) {
    console.error("Error fetching the allowance balances:", error)
    throw error
  }
}

export const getAllPeople = async (offset = 0, limit = 1000) => {
  const people = (await axios.get<PeopleReponse>(`${peopleBaseUrl}/people/v2/people?offset=${offset}&limit=${limit}`))
    .data

  return people
}

export const getPersonDetails = async (companyId: Uuid, userId: string) => {
  const person = (await axios.get<PersonDetailResponse>(`${peopleBaseUrl}/v1/companies/${companyId}/people/${userId}`))
    .data
  return person
}

export const getPeopleSearch = async (name = "", email = "", company = "", offset = 0, limit = 1000) => {
  const queryParams = [
    name && `name=${name}`,
    email && `email=${email}`,
    company && `company=${company}`,
    `offset=${offset}`,
    `limit=${limit}`,
  ]
    .filter(Boolean)
    .join("&")

  return (await axios.get<PeopleReponse>(`${peopleBaseUrl}/people/v2/people/search?${queryParams}`)).data
}

export const getBusinessUnit = async (companyId: string) =>
  (await axios.get<BusinessUnit[]>(`${tcHubBaseUrl}/v1/company/${companyId}/business-units`)).data

export const getPersonRpDetails = async (employmentId: string) => {
  const personRpDetails = (await axios.get(`${ledgerBaseUrl}/v1/recurring-premiums/employment/${employmentId}`)).data

  return personRpDetails
}

export const createNewFundingEvent = async (
  companyId: string,
  data: CreateFundingEventRequest
): Promise<CreateFundingEventResponse> => {
  const companyFundingEventResponse = (
    await axios.post<CreateFundingEventResponse>(
      `${tcHubBaseUrl}/funding/v1/companies/${companyId}/funding-events`,
      data
    )
  ).data

  return companyFundingEventResponse
}

export const createApplicationCsvDownloadRequest = async ({
  healthBenefitElectionIds,
  minimalFieldsOnly,
  extendedFields,
  escapeQuotes,
  escapeApostrophe,
}: {
  healthBenefitElectionIds: string[]
  minimalFieldsOnly: boolean
  extendedFields: boolean
  escapeQuotes: boolean
  escapeApostrophe: boolean
}) => {
  const response = await axios.post(`${tcHubBaseUrl}/benefitelection/v1/applications`, {
    healthBenefitElectionIds,
    minimalFieldsOnly,
    extendedFields,
    escapeQuotes,
    escapeApostrophe,
  })

  return response.data as ApplicationResponse
}

export const createApplicationPdfDownloadRequest = async (healthBenefitElectionIds: string[]) => {
  const response = await axios.post(`${tcHubBaseUrl}/benefitelection/v1/applications/generate-pdfs`, {
    healthBenefitElectionIds,
  })

  return response.data as ApplicationResponse
}

export const getHealthBenefitElectionDetails = async (healthBenefitElectionId: string) => {
  const healthBenefitElectionDetails = (
    await axios.get(`${benefitsBaseUrl}/v1/health-benefit-elections/${healthBenefitElectionId}`)
  ).data

  return healthBenefitElectionDetails
}

export const createSubAccount = async (recurringPremiumId: string) => {
  const response = await axios.post(
    `${tcHubBaseUrl}/funding/v1/recurring-premiums/${recurringPremiumId}/sub-account`,
    {}
  )

  return response.data
}

export const updatePerson = async (values: Partial<PersonModel>, companyId: string, personId: string) => {
  const serializedPerson = getPersonWithFormatDate(values)
  const response = await axios.patch(`${tcHubBaseUrl}/v1/companies/${companyId}/people/${personId}`, serializedPerson)
  return response.data
}

export const getRecurringPremium = async (employmentId: string) => {
  const recurringPremium = (
    await axios.get<RecurringPremiumDetailModel>(
      `${ledgerBaseUrl}/v1/compliance/recurring-premiums/employment/${employmentId}`
    )
  ).data

  return recurringPremium
}

export const getPersonDetailsByEmploymentId = async (employmentId: string) =>
  (
    await axios.get<{
      employmentId: string
      personId: string
      firstName: string
      lastName: string
      email: string
      phone: string
    }>(`${peopleBaseUrl}/v1/employments/${employmentId}/details`)
  ).data

export const updateRecurringPremium = async (
  employmentId: string,
  recurringPremiumUpdate: RecurringPremiumUpdateRequest
) => {
  const response = await axios.patch(
    `${ledgerBaseUrl}/v1/recurring-premiums/employment/${employmentId}`,
    recurringPremiumUpdate
  )

  return response.data
}

export const bulkSubmitEnrollmentsToCarrier = async (healthBenefitElectionRequest: HealthBenefitElectionRequest) => {
  const response = await axios.put(`${ledgerBaseUrl}/v1/recurring-premiums/bulk-update`, healthBenefitElectionRequest)
  return response.data
}

export const copyRecurringPremium = async (
  recurringPremiumId: string,
  recurringPremiumCopyUpdate: RecurringPremiumCopyRequest
) => {
  const response = await axios.patch(
    `${ledgerBaseUrl}/v1/recurring-premiums/${recurringPremiumId}/copy`,
    recurringPremiumCopyUpdate
  )

  return response.data
}

export const createTcHubRecurringPremium = async (
  employmentId: string,
  recurringPremium: CreateRecurringPremiumTcHubRequest
) => {
  const response = await axios.post(`${ledgerBaseUrl}/v1/recurring-premiums`, {
    employmentId,
    ...recurringPremium,
  })

  return response.data
}
