import { EmotionJSX } from "@emotion/react/types/jsx-namespace"
import { Box, CircularProgress } from "@mui/material"
import { ReactNode } from "react"

export const Loader = () => (
  <Box
    sx={{
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      minHeight: "100%",
    }}
  >
    <CircularProgress color="secondary" />
  </Box>
)

interface LoadingWrapperProps {
  children: ReactNode
  loading: boolean
  component?: () => EmotionJSX.Element
}

export const LoadingWrapper = ({ children, loading, component: Component = Loader }: LoadingWrapperProps) =>
  loading ? <Component /> : children
