import { ReturnButton } from "@/components/Buttons"
import { DatePickerField } from "@/components/DatePickerField"
import { DrawerForm } from "@/components/DrawerForm"
import { FlowNavigationButtons } from "@/components/FlowNavigationButtons"
import { RadioGroupCard } from "@/components/RadioGroupCard"
import { SelectField } from "@/components/SelectField"
import { FirstNameTextField, LastNameTextField, PreferredNameTextField } from "@/components/TextFields"
import { ZipCodeCountyAutoComplete } from "@/components/ZipCodeCountyAutoComplete"
import { GENDER_OPTIONS, YES_NO_OPTIONS_BOOLEAN } from "@/constants"
import {
  BENEFITS_ELECTION_PATHS,
  BYOP,
  FAMILY_RELATIONSHIP_OPTIONS,
  RECURRING_REIMBURSEMENT,
  SELF,
} from "@/features/BenefitsElection/benefitsElectionConstants"
import { useShoppingUrl } from "@/features/BenefitsElection/benefitsElectionService"
import { useBenefitsElectionStore, useUpdateSteps } from "@/features/BenefitsElection/benefitsElectionStore"
import {
  DoctorPreference,
  DrugPreference,
  HospitalPreference,
  PersonalInformation,
  ProviderPreferenceId,
  ShoppingPerson,
  ShoppingUrl,
  ZipCode,
} from "@/features/BenefitsElection/benefitsElectionTypes"
import { formatStoredPersonalInformation } from "@/features/BenefitsElection/benefitsElectionUtils"
import {
  DifferentAddressAlert,
  FamilyMedicaidAlert,
  MedicareAlert,
} from "@/features/BenefitsElection/components/BenefitsElectionAlerts"
import { useNotifications } from "@/services/notificationService"
import { createDataQa } from "@/utils/dataQa"
import { getOnlyDate } from "@/utils/dates"
import { transformDate } from "@/utils/formatting"
import { createUuid } from "@/utils/util"
import { Grid, Typography } from "@mui/material"
import { Formik } from "formik"
import { useEffect, useState } from "react"
import * as Yup from "yup"
import { useValidateMedicareQualification } from "../../../hooks/useValidateMedicareQualification"
import { DoctorsSearchField } from "../Doctors"
import { HospitalsSearchField } from "../Hospitals"
import { commonPersonalInformationValidationSchema } from "../PersonalInfo"
import { DrugsSearchField } from "../Prescriptions"

const familyMemberFormInitialValues = {
  dateOfBirth: "",
  isEnrolledInMedicaid: "",
  firstName: "",
  gender: "",
  lastName: "",
  preferredName: "",
  relationship: "",
  isTobaccoUser: "",
  zipCode: null,
  isMedicareEligible: false,
}

const setUpFormInitialValues = (familyMember: ShoppingPerson | undefined, zipCode?: PersonalInformation["zipCode"]) => {
  if (familyMember?.personalInformation) {
    return formatStoredPersonalInformation(familyMember.personalInformation)
  } else if (zipCode) {
    return { ...familyMemberFormInitialValues, zipCode }
  } else {
    return familyMemberFormInitialValues
  }
}

interface CompleteFormValues {
  personalInformationValues: PersonalInformation
  doctorSelections: DoctorPreference[]
  hospitalSelections: HospitalPreference[]
  prescriptionSelections: DrugPreference[]
  shoppingPersonId?: ShoppingPerson["shoppingPersonId"]
}
const createShoppingPersonFromFormValues = ({
  personalInformationValues,
  doctorSelections,
  hospitalSelections,
  prescriptionSelections,
  shoppingPersonId = createUuid(),
}: CompleteFormValues): ShoppingPerson => {
  const personalInformation: PersonalInformation = {
    ...personalInformationValues,
    dateOfBirth: transformDate(personalInformationValues.dateOfBirth as Date),
  }

  const newShoppingPerson: ShoppingPerson = {
    shoppingPersonId,
    personalInformation,
    planMember: true,
    doctors: doctorSelections,
    hospitals: hospitalSelections,
    prescriptions: prescriptionSelections,
  }

  return newShoppingPerson
}

// Convert type to array of strings
const createFamilyMemberValidationSchema = commonPersonalInformationValidationSchema.shape({
  relationship: Yup.string().required("Please select a relation"),
})

export const ADD_MEMBER_TITLE = "Let’s add a new family member"
export const EDIT_MEMBER_TITLE = "Edit family member"
export const EDIT_EMPLOYEE_TITLE = "Edit my card"

const ADD_OR_EDIT_MEMBER_DESCRIPTION = "Please enter the information below for your family member."
const EDIT_EMPLOYEE_DESCRIPTION = "Please enter the information below for yourself."

const getFormTitleAndDescription = (isEditing: boolean, isEmployee: boolean) => {
  if (!isEditing) {
    return {
      title: ADD_MEMBER_TITLE,
      description: ADD_OR_EDIT_MEMBER_DESCRIPTION,
    }
  }
  if (isEmployee) {
    return {
      title: EDIT_EMPLOYEE_TITLE,
      description: EDIT_EMPLOYEE_DESCRIPTION,
    }
  }

  return {
    title: EDIT_MEMBER_TITLE,
    description: ADD_OR_EDIT_MEMBER_DESCRIPTION,
  }
}

// FUTURE: Remove this constant to show the preferences section again
const SHOW_FAMILY_PREFERENCES = false

interface FamilyMemberFormikProps {
  isEmployee: boolean
  shoppingUrl?: ShoppingUrl
  planYear: number
  isPending: boolean
  isRecurringReimbursementAvailable?: boolean
  preferences?: {
    zipCode: ZipCode
    defaultZipCode: string
  }
  isFamilyPage?: boolean
  isByop?: boolean
  editProps?: EditFamilyMemberProps
  updateSteps?: (isMedicare: boolean, isMedicaid: boolean, isByop: boolean) => void
  createFamilyMember: (newMember: ShoppingPerson) => Promise<void>
  onClose: () => void
}

export const FamilyMemberFormik = ({
  isEmployee,
  preferences,
  shoppingUrl,
  planYear,
  isPending,
  isRecurringReimbursementAvailable = false,
  isFamilyPage = false,
  updateSteps,
  onClose,
  createFamilyMember,
  editProps,
  isByop = false,
}: FamilyMemberFormikProps) => {
  const { currentEditingMember, editFamilyMember } = editProps ?? {}
  const { notify } = useNotifications("save-family-member", true)
  const isEditing = !!currentEditingMember
  const showMemberWarnings = isFamilyPage
  const { zipCode, defaultZipCode } = preferences ?? {}

  const [doctorSelections, setDoctorSelections] = useState<DoctorPreference[]>([])
  const [hospitalSelections, setHospitalSelections] = useState<HospitalPreference[]>([])
  const [prescriptionSelections, setPrescriptionSelections] = useState<DrugPreference[]>([])
  const { isMedicareEligible, handleBirthDateChange, handleBirthDateTextChange } =
    useValidateMedicareQualification(currentEditingMember)

  const { title, description } = getFormTitleAndDescription(isEditing, isEmployee)

  const addDoctorSelection = (selection: DoctorPreference) => {
    setDoctorSelections(previousSelections => [...previousSelections, selection])
  }

  const removeDoctorSelection = (removeId: ProviderPreferenceId) => {
    setDoctorSelections(previousSelections =>
      previousSelections.filter(selectedDoctor => selectedDoctor.id !== removeId)
    )
  }

  const addHospitalSelection = (selection: HospitalPreference) => {
    setHospitalSelections(previousSelections => [...previousSelections, selection])
  }

  const removeHospitalSelection = (removeId: string) => {
    setHospitalSelections(previousSelections =>
      previousSelections.filter(selectedHospital => selectedHospital.id !== removeId)
    )
  }

  const addPrescriptionSelection = (selection: DrugPreference) => {
    setPrescriptionSelections(previousSelections => [...previousSelections, selection])
  }

  const removePrescriptionSelection = (removeId: string) => {
    setPrescriptionSelections(previousSelections =>
      previousSelections.filter(selectedPrescription => selectedPrescription.id !== removeId)
    )
  }

  const resetPreferences = () => {
    setDoctorSelections([])
    setHospitalSelections([])
    setPrescriptionSelections([])
  }

  const handleClose = () => {
    resetPreferences()
    onClose()
  }

  useEffect(() => {
    setDoctorSelections(currentEditingMember?.doctors ?? [])
    setHospitalSelections(currentEditingMember?.hospitals ?? [])
    setPrescriptionSelections(currentEditingMember?.prescriptions ?? [])
  }, [currentEditingMember])

  return (
    <Formik
      // SAFETY: The validation schema enforces this type
      initialValues={setUpFormInitialValues(currentEditingMember, zipCode) as PersonalInformation}
      validationSchema={createFamilyMemberValidationSchema}
      enableReinitialize
      validateOnMount
      onSubmit={async (values, { resetForm }) => {
        try {
          const {
            firstName,
            lastName,
            isEnrolledInMedicaid,
            dateOfBirth,
            gender,
            preferredName,
            zipCode: filledZipCode,
            relationship,
            isTobaccoUser,
          } = values

          const shoppingPersonObject = createShoppingPersonFromFormValues({
            personalInformationValues: {
              firstName,
              lastName,
              isEnrolledInMedicaid,
              dateOfBirth,
              gender,
              preferredName,
              zipCode: filledZipCode,
              isMedicareEligible,
              relationship,
              isTobaccoUser,
            },
            doctorSelections,
            hospitalSelections,
            prescriptionSelections,
          })

          if (isEditing) {
            shoppingPersonObject.shoppingPersonId = currentEditingMember.shoppingPersonId
            await editFamilyMember?.(shoppingPersonObject)
          } else {
            await createFamilyMember(shoppingPersonObject)
          }
          const employeeMessage = "All changes have been saved"

          const familyMemberMessage = `You have successfully ${
            isEditing ? "updated your family member" : "added a new family member"
          } `

          if (isEmployee) {
            updateSteps?.(isMedicareEligible, isEnrolledInMedicaid, isByop)
          }

          notify(isEmployee ? employeeMessage : familyMemberMessage, "success")
        } catch {
          notify(`The family member could not be ${isEditing ? "updated" : "created"}`, "error")
        } finally {
          resetForm()
          resetPreferences()
          handleClose()
        }
      }}
    >
      {({ errors, handleChange, handleBlur, handleSubmit, touched, values, isValid, setFieldValue }) => {
        const showDifferentAddressAlert =
          !isEmployee && !!values.zipCode?.fipsCode && values.zipCode?.fipsCode !== zipCode?.fipsCode

        return (
          <form noValidate onSubmit={handleSubmit} data-qa="family-form">
            <Grid container spacing={4}>
              {isFamilyPage && (
                <Grid item xs={12}>
                  <ReturnButton
                    data-qa={createDataQa("family-form-back-to-family-button")}
                    text="Family"
                    handleClose={handleClose}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                {isFamilyPage && (
                  <Typography variant="h1" data-qa="family-form-title">
                    {title}
                  </Typography>
                )}

                <Typography variant="body1" gutterBottom data-qa="family-form-description">
                  {description}
                </Typography>
              </Grid>
            </Grid>
            {showMemberWarnings && (
              <Grid container spacing={4} mt={0}>
                {values.isEnrolledInMedicaid && <FamilyMedicaidAlert />}
                {isMedicareEligible && (
                  <MedicareAlert
                    shoppingUrl={shoppingUrl}
                    isAvailable={isEmployee && isRecurringReimbursementAvailable}
                    addresseeStatement="This individual is"
                  />
                )}
                {showDifferentAddressAlert && <DifferentAddressAlert />}
              </Grid>
            )}

            <Grid container spacing={{ xs: 3, md: 4 }} sx={{ mt: "0 !important" }}>
              <Grid item xs={12}>
                <Typography variant="h5" data-qa="family-form-personal-information" mt={2}>
                  Personal information
                </Typography>
              </Grid>
              <Grid item xs={12} md={isEmployee ? 4 : 6}>
                <FirstNameTextField sx={{ mt: 2 }} />
              </Grid>
              <Grid item xs={12} md={isEmployee ? 4 : 6}>
                <LastNameTextField sx={{ mt: 2 }} />
              </Grid>
              {isEmployee && (
                <Grid item xs={12} md={4}>
                  <PreferredNameTextField sx={{ mt: 2 }} />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <DatePickerField
                  data-qa="family-form-birth-date"
                  name="dateOfBirth"
                  label="Date of birth"
                  required
                  fullWidth
                  variant="outlined"
                  type="date"
                  maxDate={new Date()}
                  value={getOnlyDate(values.dateOfBirth ?? null)}
                  error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
                  helperText={touched.dateOfBirth && errors.dateOfBirth}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onDateAccept={handleBirthDateChange}
                  onInputChange={handleBirthDateTextChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <SelectField
                  dataQa="family-form-gender-select"
                  data={GENDER_OPTIONS}
                  type="text"
                  name="gender"
                  label="Gender"
                  required
                  value={values.gender}
                  placeholder="Select one"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <SelectField
                  dataQa="family-form-relation-select"
                  data={isEmployee ? FAMILY_RELATIONSHIP_OPTIONS.slice(0, 1) : FAMILY_RELATIONSHIP_OPTIONS.slice(1)}
                  type="text"
                  name="relationship"
                  label="Relation"
                  required
                  value={values.relationship}
                  placeholder="Select one"
                  disabled={isEmployee}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  sx={{ my: 0 }}
                />
              </Grid>

              <RadioGroupCard
                name="isTobaccoUser"
                formName="family-form"
                elements={YES_NO_OPTIONS_BOOLEAN}
                value={values.isTobaccoUser}
                handleChange={setFieldValue}
                required
                label="Does this person use tobacco?"
                labelVariant="h5"
              />

              <RadioGroupCard
                name="isEnrolledInMedicaid"
                formName="family-form"
                elements={YES_NO_OPTIONS_BOOLEAN}
                value={values.isEnrolledInMedicaid}
                handleChange={(field, value) => setFieldValue(field, value)}
                required
                label="Is this person enrolled in Medicaid?"
                labelVariant="h5"
              />

              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant="h5" data-qa="family-form-medical-preferences-label">
                  ZIP Code
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ZipCodeCountyAutoComplete
                  name="zipCode"
                  touched={Boolean(touched.zipCode)}
                  error={!!errors.zipCode}
                  sx={{ mt: 2 }}
                  setFieldValue={setFieldValue}
                  value={values.zipCode}
                  handleBlur={handleBlur}
                />
              </Grid>
              {SHOW_FAMILY_PREFERENCES && preferences && (
                <>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Typography variant="h5" data-qa="family-form-medicaid-label">
                      Do you have any additional Doctors / Facilities / Prescriptions?
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sx={{ mt: { xs: 3, md: 2 } }}>
                    <DoctorsSearchField
                      selections={doctorSelections}
                      handleSelection={addDoctorSelection}
                      handleDelete={removeDoctorSelection}
                      planYear={planYear}
                      zipCode={values.zipCode?.zipCode ?? defaultZipCode!}
                      radius={25}
                      title={undefined}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ mt: 4 }}>
                    <HospitalsSearchField
                      planYear={planYear}
                      zipCode={values.zipCode?.zipCode ?? defaultZipCode!}
                      radius={25}
                      selections={hospitalSelections}
                      handleSelection={addHospitalSelection}
                      handleDelete={removeHospitalSelection}
                      title={undefined}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ mt: { xs: 3, md: 2 }, mb: { xs: 5, md: 1 } }}>
                    <DrugsSearchField
                      selections={prescriptionSelections}
                      handleSelection={addPrescriptionSelection}
                      handleDelete={removePrescriptionSelection}
                      planYear={planYear}
                      zipCode={values.zipCode?.zipCode ?? defaultZipCode!}
                      radius={25}
                      title={undefined}
                    />
                  </Grid>
                </>
              )}

              <FlowNavigationButtons
                continueLabel={isEditing ? "Save Changes" : "Save Family Member"}
                type="submit"
                {...(isFamilyPage ? { handleBack: handleClose } : { handleSkip: handleClose })}
                disabled={!isValid}
                isSubmitting={isPending}
                sx={{ mt: 8 }}
                skipLabel={isFamilyPage ? undefined : "Cancel"}
              />
              <Grid item>
                <Typography variant="caption">
                  * Required for the insurance company. These are the only options they offer.
                </Typography>
              </Grid>
            </Grid>
          </form>
        )
      }}
    </Formik>
  )
}

interface EditFamilyMemberProps {
  editFamilyMember: (editedMember: ShoppingPerson) => Promise<void>
  currentEditingMember?: ShoppingPerson
}

interface FamilyMemberFormProps {
  open: boolean
  onClose: () => void
  createFamilyMember: (newMember: ShoppingPerson) => Promise<void>
  editProps?: EditFamilyMemberProps
  zipCode: ZipCode
  isPending: boolean
}

export const FamilyMemberForm = ({
  open: visible,
  onClose,
  createFamilyMember,
  editProps,
  zipCode,
  isPending,
}: FamilyMemberFormProps) => {
  const { currentEditingMember } = editProps ?? {}
  const updateSteps = useUpdateSteps()

  const shoppingUrl = useShoppingUrl()
  const currentStep = useBenefitsElectionStore(state => state.currentStep)
  const shoppingSession = useBenefitsElectionStore(state => state.currentShoppingSession)
  const planYear = Number(shoppingSession?.enrollmentTimePeriod?.planYear ?? new Date().getFullYear())

  const isEmployee = currentEditingMember?.personalInformation?.relationship === SELF

  const isByop = BYOP === shoppingSession.enrollmentTimePeriod.enrollmentTimePeriodType

  // SAFETY: This page is only accessible after the zip code has been set
  const defaultZipCode = zipCode?.zipCode!

  let isRecurringReimbursementAvailable = false

  for (const step of BENEFITS_ELECTION_PATHS) {
    if (step === RECURRING_REIMBURSEMENT) {
      isRecurringReimbursementAvailable = true
    }
    if (step === currentStep) break
  }

  return (
    <DrawerForm
      open={visible}
      onClose={onClose}
      paperStyle={{ padding: "1.5rem 2rem", width: { xs: "90%", md: "80%" }, maxWidth: "75rem" }}
      data-qa="family-form-drawer"
    >
      <FamilyMemberFormik
        isEmployee={isEmployee}
        preferences={{ zipCode, defaultZipCode }}
        planYear={planYear}
        shoppingUrl={shoppingUrl}
        isPending={isPending}
        isRecurringReimbursementAvailable={isRecurringReimbursementAvailable}
        createFamilyMember={createFamilyMember}
        editProps={editProps}
        onClose={onClose}
        updateSteps={updateSteps}
        isFamilyPage
        isByop={isByop}
      />
    </DrawerForm>
  )
}
