import { useDashboardItems } from "@/components/Sidebar/dashboardItems"
import { useAuth } from "@/features/Auth/useAuth"
import { ACTIVE } from "@/features/Funding/fundingConstants"
import { useHrisConfig } from "@/features/Hris/hrisService"
import { HRIS_STATUS_NOT_STARTED } from "@/features/Hris/hrisTypes"
import { useAutoPayConfig } from "@/features/Settings/settingsService"

import { ReactNode } from "react"
import { BaseLayout } from "./BaseLayout"

interface DashboardLayoutProps {
  children?: ReactNode
  menuItems?: any[]
  isViewingTcHub?: boolean
}

export const DashboardLayout = ({ children, menuItems, isViewingTcHub }: DashboardLayoutProps) => {
  const { companyId } = useAuth()
  const { data: autoPayConfig } = useAutoPayConfig(companyId!)
  const { data: hrisConfig } = useHrisConfig(companyId!)
  const isAutoPayActive = autoPayConfig?.fundingStatus === ACTIVE
  const showHrisNavItems = hrisConfig?.status !== HRIS_STATUS_NOT_STARTED
  const hraHubMenuItems = useDashboardItems(isAutoPayActive, showHrisNavItems)

  return (
    <BaseLayout menuItems={hraHubMenuItems} isViewingTcHub={false}>
      {children}
    </BaseLayout>
  )
}
