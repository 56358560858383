import { LoadingContentArea } from "@/components/LoadingContentArea"
import { RadioGroupCard } from "@/components/RadioGroupCard"
import { SelectField } from "@/components/SelectField"
import { StyledCard } from "@/components/StyledCard"
import { ClampedTextField } from "@/components/TextFields"
import { YES_NO_OPTIONS_BOOLEAN } from "@/constants"
import {
  useGetHraPlan,
  useUpdateHraPlan,
} from "@/features/CreateCompany/components/Steps/Setup/PlanSetup/planSetupService"
import {
  CreateHraPlanValues,
  HraPlanModel,
} from "@/features/CreateCompany/components/Steps/Setup/PlanSetup/planSetupTypes"
import { CustomClasses } from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/CustomClasses"
import { EinInput } from "@/features/CreateCompany/components/Steps/Setup/Setup"
import { useGetCompany, useUpdateCompany } from "@/features/CreateCompany/components/Steps/Setup/setupService"
import { BUSINESS_STRUCTURES, INDUSTRIES, PACKAGE_NAME_OPTIONS } from "@/features/CreateCompany/createCompanyConstants"
import { CompanyModel } from "@/features/CreateCompany/createCompanyEndpoints"
import { INVITE_DATE_SET } from "@/features/EmployerOnboarding/employerOnboardingConstants"
import {
  useGetCompanyOnboardingStatus,
  useGetEmployerOnboardingStatuses,
  useUpdateEmployerOnboardingStatus,
} from "@/features/EmployerOnboarding/employerOnboardingService"
import { useUpdateWelcomeEmail } from "@/features/People/peopleService"
import { useNotifications } from "@/services/notificationService"
import { createDataQa } from "@/utils/dataQa"
import { formatDateToYyyyMmDd, getNextSixMonthsStartDates } from "@/utils/dates"
import { createDateFromText, createDateFromTextOrElse, formatDate, transformDate } from "@/utils/formatting"
import { STATE_OPTIONS } from "@/utils/States"
import { Uuid } from "@/utils/types"
import { SaveOutlined } from "@mui/icons-material"
import { Button, CircularProgress, Divider, Grid, TextField, Typography } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { DateValidationError } from "@mui/x-date-pickers/internals"
import { addDays, subDays } from "date-fns"
import { Formik, FormikProps } from "formik"
import { isDate } from "lodash"
import { useEffect, useMemo, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate, useParams } from "react-router-dom"
import { editCompanyValidationSchemaWithCompanyId, EditCompanyValues } from "../../editCompanyValidations"
import { getStatusValue } from "../TcHubCompaniesTable"
import { AdminTable } from "./TcHubCompanyProfilePage"
import { useUpdateClasses } from "./useUpdateClasses"

const alphabetizedIndustries = INDUSTRIES.toSorted((a, b) => a.label.localeCompare(b.label))

function getInitialValues(
  tchubCompany: CompanyModel,
  packageName: string,
  hraStartDate: string | Date,
  hraEndDate: string | Date
): EditCompanyValues {
  return {
    companyName: tchubCompany?.companyInfo.companyName ?? "",
    businessStructure: tchubCompany?.companyInfo.companyType ?? "",
    ein: tchubCompany?.companyInfo.ein ?? "",
    industry: tchubCompany?.companyInfo.industry ?? "",
    companyAddress: tchubCompany?.companyAddress.streetAddress ?? "",
    companyAddressLine2: tchubCompany?.companyAddress.suiteApt ?? "",
    companyCity: tchubCompany?.companyAddress.city ?? "",
    companyZip: tchubCompany?.companyAddress.zip ?? "",
    companyState: tchubCompany?.companyAddress.state ?? "",
    benefitsAdministratorFirstName: tchubCompany.benefitsAdministrator?.firstName ?? "",
    benefitsAdministratorLastName: tchubCompany.benefitsAdministrator?.lastName ?? "",
    benefitsAdministratorEmail: tchubCompany.benefitsAdministrator?.email ?? "",
    benefitsAdministratorPhoneNumber: tchubCompany.benefitsAdministrator?.phoneNumber ?? "",
    packageName,
    hraStartDate: isDate(hraStartDate) ? hraStartDate.toISOString().split("T")[0] : hraStartDate,
    autoPayAvailableOption: tchubCompany?.companyInfo.autoPayAvailableOption ?? false,
    isHrisEnabled: tchubCompany?.companyInfo.isHrisEnabled ?? false,
  }
}

const CompanyEditDetailCard = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}: FormikProps<EditCompanyValues>) => {
  const { companyId } = useParams<{ companyId: string }>()
  const { data } = useGetEmployerOnboardingStatuses(companyId!)
  const today = new Date()
  const formattedDate = formatDateToYyyyMmDd(today)

  const inviteDateSetObject = data?.find(item => item.step === "INVITE_DATE_SET")

  const inviteStatusDate = createDateFromText(inviteDateSetObject?.statusValue ?? formattedDate).toLocaleString()

  const hraStartDateText = values.hraStartDate

  const hraStartDateConverted = isDate(hraStartDateText)
    ? hraStartDateText
    : createDateFromTextOrElse(hraStartDateText, new Date())
  const startDateSubstracted = addDays(hraStartDateConverted, 1)
  const minDate = subDays(startDateSubstracted, 61)
  const displayDate = startDateSubstracted ? formatDate(startDateSubstracted) : ""

  const isPastDate = new Date(inviteStatusDate) < today

  const [date, setDate] = useState(minDate)
  const [dateError, setDateError] = useState<DateValidationError>(null)

  useEffect(() => {
    if (inviteStatusDate && createDateFromText(inviteStatusDate) > new Date()) {
      setDate(createDateFromText(inviteStatusDate))
    } else {
      setDate(new Date())
    }
  }, [inviteStatusDate])

  const errorMessage = useMemo(() => {
    if (!isPastDate) {
      switch (dateError) {
        case "invalidDate":
          return "Invitation date must be in MM/DD/YYYY format."

        case "minDate":
          return `Invitations need to be sent at most 60 days prior to the HRA start date. Your HRA start date is ${displayDate}`

        case "disablePast":
          return "Invitation date cannot be in the past"

        default:
          return ""
      }
    }
  }, [isPastDate, dateError, displayDate])

  return (
    <StyledCard>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom data-qa="person-detail-title">
            Company Details
            <Divider sx={{ mt: 4 }} />
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <ClampedTextField
            data-qa="companyName-textfield"
            type="text"
            name="companyName"
            label="Company Name"
            required
            value={values.companyName}
            error={Boolean(touched.companyName && errors.companyName)}
            fullWidth
            helperText={touched.companyName && errors.companyName}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            data={BUSINESS_STRUCTURES}
            type="text"
            name="businessStructure"
            label="Business Structure"
            placeholder="Please Select"
            required
            value={values.businessStructure}
            dataQa="businessStructure-dropdown"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ClampedTextField
            data-qa="ein-textfield"
            type="text"
            name="ein"
            label="EIN"
            value={values.ein}
            placeholder="xx-xxxxxxx"
            required
            InputProps={{ inputComponent: EinInput as any }}
            error={Boolean(touched.ein && errors.ein)}
            fullWidth
            helperText={touched.ein && errors?.ein?.length !== 1 && errors.ein}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            data={alphabetizedIndustries}
            dataQa="industries-dropdown"
            type="text"
            name="industry"
            label="Industry"
            required
            placeholder="Please Select"
            value={values.industry}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            value={values.invitationEmailDate ?? inviteStatusDate}
            defaultCalendarMonth={minDate}
            label="Invitation Email Date"
            disablePast={!isPastDate}
            disabled={isPastDate}
            readOnly={isPastDate}
            minDate={isPastDate ? new Date(inviteStatusDate) : minDate}
            onError={err => {
              setDateError(err)
            }}
            renderInput={props => (
              <TextField helperText={errorMessage} sx={{ width: { xs: "100%" } }} required {...props} />
            )}
            onChange={newDate => {
              setFieldValue("invitationEmailDate", newDate ?? date)
              setDate(newDate ?? date)
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            data-qa="isHrisEnabled"
            name="isHrisEnabled"
            value={String(values.isHrisEnabled)}
            data={YES_NO_OPTIONS_BOOLEAN.map(option => ({
              label: option.title,
              value: String(option.value),
            }))}
            type="boolean"
            label="Enabled HRIS"
            placeholder="Please select"
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          {touched.isHrisEnabled && errors.isHrisEnabled && !values.isHrisEnabled}
        </Grid>
      </Grid>
    </StyledCard>
  )
}

const CompanyEditAddressDetailCard = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}: FormikProps<EditCompanyValues>) => (
  <StyledCard>
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom data-qa="person-detail-title">
          Company Address
        </Typography>
        <Divider sx={{ mt: 4 }} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <ClampedTextField
          data-qa="street1"
          name="companyAddress"
          label="Company Address"
          value={values.companyAddress}
          fullWidth
          required
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(touched.companyAddress && errors.companyAddress)}
          helperText={touched.companyAddress && errors.companyAddress}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ClampedTextField
          data-qa="street2"
          name="companyAddressLine2"
          label="Apt, suite, etc."
          value={values.companyAddressLine2}
          fullWidth
          required
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(touched.companyAddressLine2 && errors.companyAddressLine2)}
          helperText={touched.companyAddressLine2 && errors.companyAddressLine2}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ClampedTextField
          data-qa="city"
          name="companyCity"
          label="City"
          value={values.companyCity}
          fullWidth
          required
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(touched.companyCity && errors.companyCity)}
          helperText={touched.companyCity && errors.companyCity}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <SelectField
          dataQa="companyState"
          data={STATE_OPTIONS}
          type="text"
          name="companyState"
          label="State"
          required
          value={values.companyState}
          placeholder="Please Select"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ClampedTextField
          data-qa="companyZip"
          name="companyZip"
          label="Zip"
          value={values.companyZip}
          fullWidth
          required
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(touched.companyZip && errors.companyZip)}
          helperText={touched.companyZip && errors.companyZip}
        />
      </Grid>
    </Grid>
  </StyledCard>
)

interface HraPlanSectionProps {
  hraPlan: HraPlanModel
  formValues: FormikProps<EditCompanyValues>
}

const CompanyEditPlanCard = ({
  hraPlan,
  formValues: { values, errors, touched, handleChange, handleBlur, setFieldValue },
}: HraPlanSectionProps) => (
  <StyledCard>
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Typography variant="h5" data-qa="person-detail-title">
          HRA Plan Details
        </Typography>
        <Divider sx={{ mt: 4 }} />
      </Grid>

      <Grid item xs={12}>
        <RadioGroupCard
          name="packageName"
          formName="plan-setup"
          value={values.packageName}
          handleChange={setFieldValue}
          elements={PACKAGE_NAME_OPTIONS}
          label=" Package Name:"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          data-qa="hraToStartDate"
          name="hraStartDate"
          value={values.hraStartDate}
          data={getNextSixMonthsStartDates(values.hraStartDate)}
          disabled={new Date(values.hraStartDate) < new Date()}
          type="date"
          label="Start Date"
          placeholder="Please select"
          onChange={handleChange}
          onBlur={handleBlur}
          required
        />
        {touched.hraStartDate && errors.hraStartDate && !values.hraStartDate}
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          data-qa="autoPayAvailableOption"
          name="autoPayAvailableOption"
          value={String(values.autoPayAvailableOption)}
          // FUTURE: Send the boolean value without stringifying
          // Refactor SelectField to support this
          data={YES_NO_OPTIONS_BOOLEAN.map(option => ({
            label: option.title,
            value: String(option.value),
          }))}
          type="boolean"
          label="Show AutoPay Setup"
          placeholder="Please select"
          onChange={handleChange}
          onBlur={handleBlur}
          required
        />
        {touched.hraStartDate && errors.hraStartDate && !values.hraStartDate}
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" data-qa="company-detail-industry">
          Report Date:
        </Typography>
        <Typography variant="body1">{hraPlan?.dayOfMonth ?? ""}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" data-qa="company-detail-stripe-id">
          Report Frequency:
        </Typography>
        <Typography variant="body1">{hraPlan?.statementFrequency ?? ""}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" data-qa="company-detail-suite">
          Annual or Quarterly Compliance:
        </Typography>
        <Typography variant="body1">{hraPlan?.complianceFrequency ?? ""}</Typography>
      </Grid>
    </Grid>
  </StyledCard>
)

export const TcHubCompanyEdit = () => {
  const navigate = useNavigate()
  const { companyId } = useParams<{ companyId: Uuid }>()
  const { data: companyDetails, isLoading: isLoadingCompany } = useGetCompany(companyId)
  const { data: hraPlans } = useGetHraPlan(companyId)
  const { data: onboardingStatuses } = useGetCompanyOnboardingStatus(companyId!)
  const { mutateAsync: updateCompany } = useUpdateCompany(companyId!)
  const { notify } = useNotifications("edit-company", true)
  const { mutateAsync: updateWelcomeEmail } = useUpdateWelcomeEmail(companyId!)
  const { mutateAsync: updateCurrentOnboardingStatus } = useUpdateEmployerOnboardingStatus(companyId!)

  const currentHraPlan = useMemo(
    () => ({
      ...(hraPlans ? hraPlans[0] : ({ id: "" } as unknown as HraPlanModel)),
      packageName: getStatusValue(onboardingStatuses, "packageName"),
    }),
    [hraPlans, onboardingStatuses]
  )

  const { customClasses, isUpdatingClasses, updateClasses, deleteClasses } = useUpdateClasses(currentHraPlan)
  const { mutateAsync: updateHraPlan } = useUpdateHraPlan(companyId!, currentHraPlan.id)

  const handleNavigateBack = () => {
    navigate(`/admin/companies/${companyId}`)
  }

  return (
    <>
      <Helmet title="TC Hub Site Settings" />
      {isLoadingCompany ? (
        <LoadingContentArea data-qa={createDataQa("loading-company-details")} />
      ) : (
        <Formik
          initialValues={getInitialValues(
            companyDetails!,
            currentHraPlan.packageName,
            currentHraPlan.hraStartDate!,
            currentHraPlan.hraEndDate!
          )}
          validationSchema={editCompanyValidationSchemaWithCompanyId(companyId!)}
          onSubmit={async values => {
            try {
              const formValuesPlan: CreateHraPlanValues = {
                hraStartDate: values.hraStartDate,
                hraEndDate: currentHraPlan.hraEndDate!,
                statementCreationDate: currentHraPlan.dayOfMonth,
                statementFrequency: currentHraPlan.statementFrequency,
                complianceFrequency: currentHraPlan.complianceFrequency,
                packageName: values.packageName,
                hraType: currentHraPlan.hraType,
                submit: true,
              }
              await updateHraPlan(formValuesPlan)
              await updateCompany(values)
              if (values.invitationEmailDate) {
                await updateWelcomeEmail(transformDate(values.invitationEmailDate))
                await updateCurrentOnboardingStatus(
                  { step: INVITE_DATE_SET, isComplete: true, statusValue: values.invitationEmailDate },
                  {
                    onError: errorData => {
                      notify("Error updating onboarding status.", "error")
                      console.error(errorData.message)
                    },
                  }
                )
              }

              handleNavigateBack()
              notify("Company updated successfully", "success")
            } catch (error: any) {
              console.error(error)
              notify("Error updating the company", "error")
            }
          }}
        >
          {formProps => (
            <form noValidate onSubmit={formProps.handleSubmit} data-qa="edit-user-form">
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="h1" gutterBottom display="inline" data-qa="company-details-button">
                    Editing Company
                  </Typography>
                </Grid>
                <Grid item container xs={12} sm="auto" spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button data-qa="cancel-user-button" variant="outlined" onClick={handleNavigateBack}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={
                        formProps.isSubmitting || !formProps.isValid || Object.keys(formProps.errors).length > 0
                      }
                      data-qa="update-user-button"
                      startIcon={formProps.isSubmitting ? <CircularProgress size={20} /> : <SaveOutlined />}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <CompanyEditDetailCard {...formProps} />
              <CompanyEditAddressDetailCard {...formProps} />
              <AdminTable companyId={companyId!} />
              <CompanyEditPlanCard hraPlan={currentHraPlan} formValues={formProps} />
              <StyledCard>
                <Typography variant="h5" gutterBottom data-qa="person-detail-title">
                  Classes
                </Typography>
                <Divider sx={{ my: 4 }} />
                <CustomClasses
                  customClasses={customClasses}
                  createCustomClass={updateClasses}
                  deleteCustomClasses={deleteClasses}
                  isUpdatingClasses={isUpdatingClasses}
                  isAssisted
                />
              </StyledCard>
            </form>
          )}
        </Formik>
      )}
    </>
  )
}
