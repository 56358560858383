import { BaseObject } from "@/features/Auth/authTypes"
import { useRoleStore } from "@/features/Auth/roleStore"
import { setActiveCompanyById } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import {
  ADMINISTRATOR_EXTERNAL_ROLE_ID,
  EMPLOYEE_EXTERNAL_ROLE_ID,
  SYSTEM_ADMINISTRATOR_EXTERNAL_ROLE_ID,
} from "@/features/People/peopleConstants"
import { useGetAssociatedCompanies } from "@/features/People/peopleService"
import { colors } from "@/theme/palette"
import MenuIcon from "@mui/icons-material/Menu"
import { AppBar, Grid, IconButton, Toolbar, Typography, useTheme } from "@mui/material"
import { ReactElement, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { TakeCommandLogoHeader, TakeCommandMarkHeader } from "../Branding"
import { NavbarNotificationsDropdown } from "./NavbarNotificationsDropdown"
import { NavbarUserDropdown, NavbarUserDropdownProps } from "./NavbarUserDropdown"

export interface NavbarProps extends NavbarUserDropdownProps {
  hasNotificationDrawer?: boolean
  children?: ReactElement
  isViewingTcHub?: boolean
  isMobile?: boolean
  open?: boolean
  title?: string
  handleDrawerOpen?: () => void
  handleDrawerClose?: () => void
  handleDrawerToggle?: () => void
}

const useGetLabelRole = () => {
  const { activeRole } = useRoleStore.getState()

  if (activeRole === ADMINISTRATOR_EXTERNAL_ROLE_ID) return "Admin"
  if (activeRole === EMPLOYEE_EXTERNAL_ROLE_ID) return "Employee"
  return ""
}

export const Navbar = ({
  hideProfileItem,
  hasNotificationDrawer = true,
  children,
  isViewingTcHub,
  isMobile,
  handleDrawerOpen,
  handleDrawerToggle,
  open,
}: NavbarProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const [activeCompany, setActiveCompany] = useState<{ id: string; name: string } | null>(null)
  const { data: associatedCompanies, isLoading: isLoadingAssociatedCompanies } = useGetAssociatedCompanies()
  // FUTURE: start getting active companyId from auth instead of getActiveCompany
  const { isLoggedIn, user, refresh, companyId } = useAuth()
  const { setActiveRole } = useRoleStore()
  const isAdminPage = location.pathname.startsWith("/admin")

  useEffect(() => {
    if (associatedCompanies && user) {
      const selectedCompany = associatedCompanies.find((company: { id: string }) => company.id === companyId)
      if (selectedCompany) {
        setActiveCompany({ id: selectedCompany.id, name: selectedCompany.name })
      } else {
        setActiveCompany({ id: "unknown", name: "Unknown Company" })
      }
    }
  }, [associatedCompanies, user, companyId])

  const labelRole = useGetLabelRole()

  const handleCompanySelection = async (id: string) => {
    if (!user) return

    const selectedCompany = associatedCompanies?.find((company: BaseObject) => company.id === id)
    if (selectedCompany) {
      await setActiveCompanyById(user, selectedCompany.id)
      const companyRoles = user.company?.roles || []
      const hasAdminRole = companyRoles.some(
        role => role.roleId === ADMINISTRATOR_EXTERNAL_ROLE_ID || role.roleId === SYSTEM_ADMINISTRATOR_EXTERNAL_ROLE_ID
      )
      const hasEmployeeRole = companyRoles.some(role => role.roleId === EMPLOYEE_EXTERNAL_ROLE_ID)

      if (hasAdminRole) {
        setActiveRole(ADMINISTRATOR_EXTERNAL_ROLE_ID)
      } else if (hasEmployeeRole) {
        setActiveRole(EMPLOYEE_EXTERNAL_ROLE_ID)
      }

      refresh()
      navigate("/")
    }
  }

  const getLogoComponent = () => {
    if (isMobile) {
      return <TakeCommandMarkHeader />
    }
    if (isViewingTcHub) {
      return <TakeCommandLogoHeader isViewingTcHub />
    }
    return <TakeCommandLogoHeader />
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: theme.zIndex.drawer,
        backgroundColor: isViewingTcHub ? null : colors.white,
        boxShadow: 0,
        borderBottom: `1px solid ${colors.borderGray}`,
      }}
    >
      <Toolbar>
        {isMobile && (
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={isMobile ? handleDrawerToggle : handleDrawerOpen}
            sx={{ left: 16, mr: 4, ...(isMobile ? {} : open && { display: "none" }) }}
          >
            <MenuIcon sx={{ color: isViewingTcHub ? colors.white : "primary" }} />
          </IconButton>
        )}
        <Grid container alignItems="center" data-qa="navbar-container" justifyContent="space-between">
          <Grid item>{getLogoComponent()}</Grid>
          {associatedCompanies && associatedCompanies.length > 1 && !isAdminPage && (
            <Grid item xs data-qa="navbar-spacer">
              <Typography
                variant="body1bold"
                noWrap
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {activeCompany?.name}
              </Typography>
            </Grid>
          )}
          <Grid item data-qa="navbar-user-details">
            <Grid container alignItems="center">
              {isLoggedIn && hasNotificationDrawer && !isViewingTcHub && <NavbarNotificationsDropdown />}
              <NavbarUserDropdown
                hideProfileItem={hideProfileItem}
                associatedCompanies={associatedCompanies}
                onCompanySelect={handleCompanySelection}
                activeCompanyId={activeCompany?.id}
                isLoadingAssociatedCompanies={isLoadingAssociatedCompanies}
                isViewingTcHub={isViewingTcHub}
                labelRole={labelRole}
              />
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
