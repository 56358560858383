import {
  AmountTextField,
  HoursPerWeekAmountTextField,
  SingleEmployeeAmountTextFieldWithTable,
} from "@/components/AmountTextField"
import { CheckBoxGroupCard } from "@/components/CheckBoxGroupCard"
import { FlowNavigationButtons } from "@/components/FlowNavigationButtons"
import { RadioGroupCard } from "@/components/RadioGroupCard"
import { SelectField } from "@/components/SelectField"
import { SelectMultiField } from "@/components/SelectMultiField"
import { EXTERNAL_LINKS, YES_NO_OPTIONS_BOOLEAN } from "@/constants"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { Stepper } from "@/features/CreateCompany/components/Stepper"
import {
  ALL_EMPLOYEES,
  CUSTOM,
  ELIGIBLE_FOR_REIMBURSEMENT,
  PREMIUM_ONLY,
  SEASONALITY_RANGES,
  VARY_BY_AGE,
  VARY_BY_FAMILY_SIZE,
  VARY_BY_FAMILY_SIZE_AND_AGE,
} from "@/features/CreateCompany/createCompanyConstants"
import { PurchaseHraStepProps } from "@/features/CreateCompany/pages/CreateCompany"
import { useNotifications } from "@/services/notificationService"
import { createDataQa } from "@/utils/dataQa"
import { STATE_OPTIONS } from "@/utils/States"
import { Uuid } from "@/utils/types"
import { PlanStructureValidationSchema } from "@/utils/validations"
import { Alert, Box, Button, CircularProgress, Collapse, Grid, Link, Stack, Typography } from "@mui/material"
import { Formik, useFormikContext } from "formik"
import { useState } from "react"
import { To, useNavigate } from "react-router-dom"
import { useGetHraPlan } from "../PlanSetup/planSetupService"
import { HraPlanModel } from "../PlanSetup/planSetupTypes"
import { useFormValuesWithCustomClasses } from "./classesStore"
import { ReimbursementAmountInputs, WaitingPeriodRadioCard } from "./CommonComponents"
import { CustomClasses } from "./CustomClasses"
import { MultiSelectClassSection } from "./MultiSelectClassSection"
import { useCreatePlanStructure, useGetCurrentClasses, useUpdatePlanStructure } from "./planStructureService"
import {
  ClassCardKey,
  CustomClassData,
  CustomClassDataWithoutDeleted,
  CustomClassDetails,
  PlanStructureFormValues,
  PlanStructurePatchRequest,
} from "./planStructureTypes"
import { buildCustomClassesPatchRequest, SINGLE_CLASS_REIMBURSEMENT_STRUCTURE_ELEMENTS } from "./planStructureUtils"

export interface EligibleForReimbursementRadioCardProps {
  subsection?: string
  value: string
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

export const EligibleForReimbursementRadioCard = ({
  subsection,
  value,
  setFieldValue,
}: EligibleForReimbursementRadioCardProps) => (
  <Grid item xs={12}>
    <RadioGroupCard
      formName={`plan-structure${subsection && "-" + subsection}`}
      name="eligibleForReimbursement"
      value={value}
      handleChange={setFieldValue}
      elements={ELIGIBLE_FOR_REIMBURSEMENT}
    />
  </Grid>
)

interface InclusionCheckBoxCardProps {
  elements: { title: string; value: string }[]
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void
  subsection?: string
  small?: boolean
}

export const InclusionCheckBoxCard = ({ subsection, setFieldValue, small, elements }: InclusionCheckBoxCardProps) => (
  <Grid item xs={12} mt={5} mb={10}>
    <CheckBoxGroupCard
      formName={createDataQa("plan-structure", subsection)}
      handleChange={setFieldValue}
      small={small}
      elements={elements}
      error={false}
    />
  </Grid>
)

const IchraInformationBanner = () => (
  <Grid item xs={12} mb={8} data-qa="ichra-information-banner">
    <Stack
      direction={{ xs: "column", md: "row" }}
      justifyContent="space-between"
      spacing={{ xs: 4, md: 8 }}
      sx={{
        backgroundColor: "colors.lightTealShade",
        padding: 4,
        pt: 8,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography variant="h6" gutterBottom>
          Do you have questions about the ICHRA class regulations?
        </Typography>
        <Typography>
          ICHRA classes must be unique to ensure benefits are offered fairly to all employees. Any variation must be its
          own class. Look over the ICHRA class regulations for more details.
        </Typography>
      </Box>
      <Button
        variant="outlined"
        component={Link}
        href={EXTERNAL_LINKS.HRA_ICHRA_RULES}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          whiteSpace: { xs: "normal", md: "nowrap" },
          minWidth: { xs: "", md: "max-content" },
          height: { xs: "", md: "fit-content" },
        }}
      >
        Learn more about ICHRA classes
      </Button>
    </Stack>
  </Grid>
)

const ClassInclussionPreferences = () => {
  const { values, touched, errors, handleChange, setFieldValue, setFieldTouched } =
    useFormikContext<PlanStructureFormValues>()

  return (
    <>
      <MultiSelectClassSection
        title="Please select full-time, part-time, or both"
        formName="plan-structure"
        handleChange={setFieldValue}
        elements={[
          { title: "Full-Time", value: "isFullTime" },
          { title: "Part-Time", value: "isPartTime" },
        ]}
      />

      <MultiSelectClassSection
        title="Please select salary, non-salary, or both"
        formName="plan-structure"
        handleChange={setFieldValue}
        elements={[
          { title: "Salary", value: "isSalary" },
          { title: "Non-Salary", value: "isNonSalary" },
        ]}
      />

      <MultiSelectClassSection
        title="Please select seasonal, non-seasonal, or both"
        formName="plan-structure"
        handleChange={setFieldValue}
        elements={[
          { title: "Seasonal", value: "isSeasonal" },
          { title: "Non-Seasonal", value: "isNonSeasonal" },
        ]}
      />

      <Grid item xs={12}>
        <RadioGroupCard
          formName="classEditor"
          name="isSpecificGeography"
          value={values.isSpecificGeography}
          handleChange={setFieldValue}
          elements={[
            {
              title: "Specific Geographic Areas",
              value: true,
            },
            {
              title: "All Geographic Areas",
              value: false,
            },
          ]}
          label="Please select specific geographic area or all areas"
          labelVariant="h6"
          required
        />
      </Grid>

      {values.isSpecificGeography && (
        <Grid item xs={6} mt={2}>
          <SelectMultiField
            selectedValues={values.selectedGeographicValues}
            fieldLabel="Specific Geographic Area"
            required
            data={STATE_OPTIONS}
            name="selectedGeographicValues"
            sx={{ my: 0 }}
            placeholder="Specific Geographic Area"
            data-qa="geographic-select"
            onChange={handleChange}
            onBlur={() => setFieldTouched("selectedGeographicValues")}
            error={Boolean(touched.selectedGeographicValues && errors.selectedGeographicValues)}
            helperText={
              touched.selectedGeographicValues && errors.selectedGeographicValues
                ? (errors.selectedGeographicValues as string)
                : undefined
            }
          />
        </Grid>
      )}
    </>
  )
}

const AllEmployeesFormElements = () => {
  const { values, touched, errors, handleBlur, handleChange, setFieldValue } =
    useFormikContext<PlanStructureFormValues>()

  return (
    <Grid container spacing={4} mt={4}>
      <ClassInclussionPreferences />

      <Grid item xs={12} mt={6}>
        <Typography variant="h6">Reimbursement amount per month</Typography>
      </Grid>

      <Grid item xs={12} md={7}>
        <AmountTextField
          name="employeeAmount"
          touched={touched.employeeAmount!}
          errorString={errors.employeeAmount!}
          error={Boolean(touched.employeeAmount && errors.employeeAmount)}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.employeeAmount?.toString()}
          label="Employee"
          sx={{ mt: 0 }}
        />
      </Grid>

      <WaitingPeriodRadioCard subsection={ALL_EMPLOYEES} value={values.waitingPeriod} setFieldValue={setFieldValue} />
    </Grid>
  )
}

const VaryByAgeFormElements = () => {
  const [agesTableOpen, setAgesTableOpen] = useState(false)
  const { values, errors, handleBlur, handleChange, setFieldValue } = useFormikContext<PlanStructureFormValues>()

  return (
    <Grid container spacing={4} mt={4}>
      <ClassInclussionPreferences />

      <Grid item xs={12} mt={6}>
        <Typography variant="h6">Reimbursement amounts per month</Typography>
        <SingleEmployeeAmountTextFieldWithTable
          errorString={errors.employeeAmount!}
          onBlur={handleBlur}
          onChange={handleChange}
          label="Employee - Age 21"
          agesTableOpen={agesTableOpen}
          setAgesTableOpen={setAgesTableOpen}
        />
      </Grid>

      <Grid item xs={12}>
        <WaitingPeriodRadioCard subsection={VARY_BY_AGE} value={values.waitingPeriod} setFieldValue={setFieldValue} />
      </Grid>
    </Grid>
  )
}

const VaryByFamilySizeFormElements = () => {
  const { values, touched, errors, handleBlur, handleChange, setFieldValue } =
    useFormikContext<PlanStructureFormValues>()

  return (
    <Grid container spacing={4} mt={5}>
      <ClassInclussionPreferences />
      <Grid item xs={12}>
        <Typography variant="h6">Reimbursement amounts per month</Typography>
        <Typography variant="caption">
          Amounts entered for each group are total monthly costs for the entire group, not per person.
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <AmountTextField
          error={Boolean(touched.employeeAmount && errors.employeeAmount)}
          touched={touched.employeeAmount!}
          errorString={errors.employeeAmount!}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.employeeAmount?.toString()}
          label="Employee - Age 21"
          name="employeeAmount"
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <AmountTextField
          error={Boolean(touched.employeeAndSpouseAmount && errors.employeeAndSpouseAmount)}
          touched={touched.employeeAndSpouseAmount!}
          errorString={errors.employeeAndSpouseAmount!}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.employeeAndSpouseAmount?.toString()}
          label="Employee + Spouse"
          name="employeeAndSpouseAmount"
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <AmountTextField
          error={Boolean(touched.employeeAndChildrenAmount && errors.employeeAndChildrenAmount)}
          touched={touched.employeeAndChildrenAmount!}
          errorString={errors.employeeAndChildrenAmount!}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.employeeAndChildrenAmount?.toString()}
          label="Employee + Children"
          name="employeeAndChildrenAmount"
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <AmountTextField
          error={Boolean(touched.employeeAndSpouseAndChildrenAmount && errors.employeeAndSpouseAndChildrenAmount)}
          touched={touched.employeeAndSpouseAndChildrenAmount!}
          errorString={errors.employeeAndSpouseAndChildrenAmount!}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.employeeAndSpouseAndChildrenAmount?.toString()}
          label="Employee + Spouse + Children"
          name="employeeAndSpouseAndChildrenAmount"
        />
      </Grid>

      <Grid item xs={12}>
        <WaitingPeriodRadioCard
          subsection={VARY_BY_FAMILY_SIZE}
          value={values.waitingPeriod}
          setFieldValue={setFieldValue}
        />
      </Grid>
    </Grid>
  )
}

const VaryByFamilySizeAndAgeFormElements = () => {
  const { values, setFieldValue } = useFormikContext<PlanStructureFormValues>()

  return (
    <Grid container spacing={4} mt={5}>
      <ClassInclussionPreferences />

      <ReimbursementAmountInputs />
      <Grid item xs={12}>
        <WaitingPeriodRadioCard
          subsection={VARY_BY_FAMILY_SIZE_AND_AGE}
          value={values.waitingPeriod}
          setFieldValue={setFieldValue}
        />
      </Grid>
    </Grid>
  )
}

interface NeedMoreThanOneClassFormElements {
  addCustomClassToStore: (customClass: CustomClassDetails) => void
  deleteCustomClassesFromStore: (classKeys: ClassCardKey[]) => Readonly<CustomClassData>
  customClassData: CustomClassData
  isAssisted?: boolean
}
const NeedMoreThanOneClassElements = ({
  customClassData,
  addCustomClassToStore,
  deleteCustomClassesFromStore,
  isAssisted = false,
}: NeedMoreThanOneClassFormElements) => {
  const { values, touched, errors, handleBlur, handleChange } = useFormikContext<PlanStructureFormValues>()

  return (
    <Grid container spacing={1} mt={8}>
      <Grid item xs={12}>
        <Typography variant="h6" data-qa="plan-structure-custom-help-label">
          Next, we'll help you design your employee classes and reimbursement structure for each class.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption" data-qa="plan-structure-custom-help-description">
          You will need to design your classes so that each employee can only fall into one class. Don't worry, we'll
          help you make sure your classes are compliant!
        </Typography>
      </Grid>
      <Grid item xs={12} mt={3}>
        <CustomClasses
          customClasses={customClassData as CustomClassDataWithoutDeleted}
          createCustomClass={addCustomClassToStore}
          deleteCustomClasses={deleteCustomClassesFromStore}
          isAssisted={isAssisted}
        />
      </Grid>

      <Grid item xs={12} mt={7}>
        <Typography variant="h6" data-qa="plan-structure-custom-hours-label">
          How many hours per week must employees be scheduled to work to be considered full-time?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption" data-qa="plan-structure-custom-hours-description">
          This information helps us distinguish between part-time and full-time employees.
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <HoursPerWeekAmountTextField
          name="hoursPerWeekAmountLetMeCreate"
          touched={touched.hoursPerWeekAmountLetMeCreate!}
          errorString={errors.hoursPerWeekAmountLetMeCreate!}
          error={Boolean(touched.hoursPerWeekAmountLetMeCreate && errors.hoursPerWeekAmountLetMeCreate)}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.hoursPerWeekAmountLetMeCreate}
          label="Hours per week"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">Please enter the amount of hours considered to be full-time</Typography>
      </Grid>

      <Grid item xs={12} mt={7}>
        <Typography variant="h6" data-qa="plan-structure-custom-months-label">
          How many months per year must employees be scheduled to work to be considered non-seasonal?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption" data-qa="plan-structure-custom-months-description">
          This information helps us distinguish between seasonal and non-seasonal employees.
        </Typography>
      </Grid>
      <Grid item xs={12} md={7} mt={4}>
        <SelectField
          dataQa="months-per-year-option"
          data={SEASONALITY_RANGES}
          type="text"
          name="monthsPerYearSelectionLetMeCreate"
          label="Months per year"
          required
          value={values.monthsPerYearSelectionLetMeCreate}
          placeholder="Please select"
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">Please select the range of months considered to be non-seasonal</Typography>
      </Grid>
    </Grid>
  )
}

export const PLAN_STRUCTURE_INITIAL_VALUES: PlanStructureFormValues = {
  reimbursementStructure: "",
  needMoreThanOneClass: "no-set",
  isSpecificGeography: false,
  selectedGeographicValues: [],
  isFullTime: false,
  isPartTime: false,
  isSalary: false,
  isNonSalary: false,
  isSeasonal: false,
  isNonSeasonal: false,
  eligibleForReimbursement: PREMIUM_ONLY,
  waitingPeriod: "",
  employeeAmount: "",
  employeeAndChildrenAmount: "",
  employeeAndSpouseAmount: "",
  employeeAndSpouseAndChildrenAmount: "",
  hoursPerWeekAmountLetMeCreate: "",
  monthsPerYearSelectionLetMeCreate: "",
  submit: "false",
}

const hasErrors = (touched: { [field: string]: boolean }, errors: { [field: string]: string | string[] }) =>
  Object.keys(touched).some(field => errors[field])

interface BasePlanStructureProps extends PurchaseHraStepProps {
  companyId: Uuid
  hraPlan: HraPlanModel
  isLoadingInformation?: boolean
  userId?: string
  nextStep: To
  previousStep: To
  showIchraBanner?: boolean
  refresh?: () => Promise<void>
  isAssisted?: boolean
}

export const BasePlanStructure = ({
  stepData,
  companyId,
  hraPlan,
  isLoadingInformation = false,
  userId,
  nextStep,
  previousStep,
  showIchraBanner = false,
  isAssisted = false,
  refresh,
}: BasePlanStructureProps) => {
  const navigate = useNavigate()

  const { notify: notifyCreate } = useNotifications("create-plan-structure-classes")
  const { notify: notifyUpdate } = useNotifications("update-plan-structure-classes")

  const { mutateAsync: createPlanStructure, isPending: isCreating } = useCreatePlanStructure(companyId, hraPlan.id)
  const { mutateAsync: updatePlanStructure, isPending: isUpdating } = useUpdatePlanStructure(companyId, hraPlan.id)
  const { data, isLoading: isLoadingClasses } = useGetCurrentClasses(companyId, hraPlan.id) // Retrieve existing data from API

  const classesStoreKey = userId ? `${userId}-${companyId}-classes` : `${companyId}-classes`
  const isPending = isCreating || isUpdating
  const isLoading = isLoadingInformation || isLoadingClasses

  const {
    initialFormValues,
    customClassData,
    clearCustomClassesFromStore,
    addCustomClassToStore,
    deleteCustomClassesFromStore,
    loadedClassesType,
    deletedClasses,
  } = useFormValuesWithCustomClasses({ data, hraPlan, classesStoreKey })

  const createNewClasses = async (values: PlanStructureFormValues) => {
    if (values.reimbursementStructure === CUSTOM) {
      await createPlanStructure({
        values,
        customclassValues: {
          seasonalMonthQualification: Number(values.monthsPerYearSelectionLetMeCreate),
          partTimeHourQualification: Number(values.hoursPerWeekAmountLetMeCreate),
          customClassData,
        },
      })
      clearCustomClassesFromStore()
    } else {
      await createPlanStructure({
        values,
        customclassValues: null,
      })
    }

    //update user context -- to reload companyHRAPlan
    await refresh?.()

    notifyCreate("Successfully created Plan Structure classes", "success")
    navigate(nextStep)
  }

  const showUpdateSuccessMessage = (successMessage = "Successfully updated Plan Structure classes") => {
    notifyUpdate(successMessage, "success")
    navigate(nextStep)
  }

  const updateCustomClasses = async (patchRequest: PlanStructurePatchRequest) => {
    if (patchRequest) {
      await updatePlanStructure(patchRequest)
      clearCustomClassesFromStore()
      showUpdateSuccessMessage()
    } else {
      showUpdateSuccessMessage("No changes made to Plan Structure Custom Classes")
    }
  }

  /**
   * Updates the plan structure based on four cases:
   * 1. A fixed class was updated.
   * 2. Custom classes were deleted, and a fixed class was created.
   * 3. Custom classes were updated.
   * 4. A fixed class was deleted, and custom classes were created.
   * @param values Form values
   */
  const updateClasses = async (values: PlanStructureFormValues) => {
    if (values.reimbursementStructure !== CUSTOM) {
      // Case 1: Fixed class was updated
      if (loadedClassesType === "FIXED") {
        const patchRequest = buildCustomClassesPatchRequest({
          fixedClassData: values,
          fixedClassOperation: "UPDATE",
        })

        await updatePlanStructure(patchRequest!)
        showUpdateSuccessMessage()
      }
      // Case 2: Custom classes were deleted, and a fixed class was created.
      else if (loadedClassesType === CUSTOM) {
        const patchRequest = buildCustomClassesPatchRequest({
          fixedClassData: values,
          fixedClassOperation: "CREATE",
          loadedData: data,
        })

        await updatePlanStructure(patchRequest!)
        showUpdateSuccessMessage()
      }
      clearCustomClassesFromStore()
    } else if (values.reimbursementStructure === CUSTOM) {
      // Case 3: Custom classes were updated
      if (loadedClassesType === CUSTOM) {
        const deletedClassesArray = deletedClasses ? (deletedClasses as string[]) : []

        const patchRequest = buildCustomClassesPatchRequest({
          customClassData,
          deletedClasses: deletedClassesArray,
          loadedData: data,
        })

        await updateCustomClasses(patchRequest!)
      }
      // A fixed class was deleted, and custom classes were created.
      else if (loadedClassesType === "FIXED") {
        // Implement the logic for this case
        const patchRequest = buildCustomClassesPatchRequest({
          fixedClassData: values,
          fixedClassOperation: "DELETE",
          customClassData,
        })

        await updatePlanStructure(patchRequest!)
        showUpdateSuccessMessage()
      }
      clearCustomClassesFromStore()
    }
  }

  return (
    <Formik
      initialValues={initialFormValues}
      enableReinitialize
      validationSchema={PlanStructureValidationSchema}
      validateOnMount
      validateOnChange
      onSubmit={async (values, { setStatus, setSubmitting }) => {
        const isUpdate = !!loadedClassesType

        const errorMessage = isUpdate
          ? `Error updating classes for company ${companyId}`
          : `Error creating classes for company ${companyId}`

        try {
          await (isUpdate ? updateClasses(values) : createNewClasses(values))
        } catch (error: any) {
          console.error(error)
          setStatus({ success: false })
          setSubmitting(false)
          notifyCreate(errorMessage, "error")
        }
      }}
    >
      {({ errors, handleSubmit, isValid, values, touched, setFieldValue, resetForm }) => {
        const doesNotHaveClasses = values.needMoreThanOneClass === true && Object.keys(customClassData).length === 0
        return (
          <>
            <Stepper steps={stepData} activeStepIndex={2} isError={hasErrors(touched, errors)} />
            <form noValidate onSubmit={handleSubmit} data-qa="create-company-setup-form">
              {errors.submit && (
                <Alert severity="warning" data-qa="error-banner-create-company-setup-form">
                  {errors.submit}
                </Alert>
              )}
              <Grid container spacing={4} mt={5}>
                <Grid item xs={12} mb={4}>
                  <Stack direction="row" spacing={4} alignItems="center">
                    <Typography variant="h1">Plan Structure</Typography>
                    {isLoading && <CircularProgress size="1.5rem" />}
                  </Stack>
                </Grid>

                {showIchraBanner ? (
                  <IchraInformationBanner />
                ) : (
                  <Grid item xs={12}>
                    <Typography variant="h6">How do you want to structure your reimbursements?</Typography>
                    <Typography variant="caption">You will only reimburse what employees actually spend.</Typography>
                  </Grid>
                )}

                <RadioGroupCard
                  name="needMoreThanOneClass"
                  formName="plan-structure"
                  elements={YES_NO_OPTIONS_BOOLEAN}
                  value={values.needMoreThanOneClass}
                  handleChange={(field: string, value: boolean | string) => {
                    resetForm()
                    setFieldValue(field, value)
                    setFieldValue("reimbursementStructure", value ? "CUSTOM" : "")
                  }}
                  required
                  label="Do you need more than one class?"
                  labelVariant="h6"
                />
              </Grid>

              <Collapse
                in={!values.needMoreThanOneClass && values.needMoreThanOneClass !== "no-set"}
                timeout="auto"
                orientation="vertical"
                translate="yes"
                unmountOnExit
              >
                <RadioGroupCard
                  formName="plan-structure"
                  name="reimbursementStructure"
                  value={values.reimbursementStructure}
                  handleChange={setFieldValue}
                  elements={SINGLE_CLASS_REIMBURSEMENT_STRUCTURE_ELEMENTS}
                  label="How do you want to structure your reimbursements?"
                  labelVariant="h6"
                  caption="You will only reimburse what employees actually spend."
                  sx={{ mt: 4 }}
                  required
                />

                {values.reimbursementStructure === ALL_EMPLOYEES && <AllEmployeesFormElements />}

                {values.reimbursementStructure === VARY_BY_AGE && <VaryByAgeFormElements />}

                {values.reimbursementStructure === VARY_BY_FAMILY_SIZE && <VaryByFamilySizeFormElements />}

                {values.reimbursementStructure === VARY_BY_FAMILY_SIZE_AND_AGE && (
                  <VaryByFamilySizeAndAgeFormElements />
                )}
              </Collapse>

              <Collapse
                in={values.needMoreThanOneClass === true}
                orientation="vertical"
                translate="yes"
                unmountOnExit
                timeout="auto"
              >
                <NeedMoreThanOneClassElements
                  customClassData={customClassData}
                  addCustomClassToStore={addCustomClassToStore}
                  deleteCustomClassesFromStore={deleteCustomClassesFromStore}
                  isAssisted={isAssisted}
                />
              </Collapse>

              <Grid item xs={12} mt={8}>
                <FlowNavigationButtons
                  handleBack={() => {
                    navigate(previousStep)
                  }}
                  type="submit"
                  disabled={!isValid || doesNotHaveClasses}
                  isSubmitting={isPending}
                />
              </Grid>
            </form>
          </>
        )
      }}
    </Formik>
  )
}

export const PlanStructure = ({ stepData }: PurchaseHraStepProps) => {
  const { refresh, user } = useAuth()
  const companyId = getActiveCompany(user)?.companyId as Uuid
  const { data: currentHraPlans, isLoading: isLoadingHraPlan } = useGetHraPlan(companyId)
  const hraPlan = currentHraPlans?.[0] ?? ({ id: "" } as unknown as HraPlanModel)

  return (
    <BasePlanStructure
      stepData={stepData}
      companyId={companyId}
      hraPlan={hraPlan}
      isLoadingInformation={isLoadingHraPlan}
      userId={user!.id}
      refresh={refresh}
      nextStep="/employer-onboarding"
      showIchraBanner
      previousStep="/create-company/plan-setup"
    />
  )
}
