import { THEMES } from "@/constants"
import { blue, green, grey, indigo, red } from "@mui/material/colors"
import { PaletteColorOptions } from "@mui/material/styles"
import { ThemeVariant, UseMediaQueryOptions } from "@mui/system"
import merge from "deepmerge"
import { colors, CustomColors, takeCommandPrimary, takeCommandSecondary } from "./palette"

declare module "@mui/system" {
  export function useMediaQuery<ThemeType = Theme>(
    queryInput: string | ((theme: ThemeType) => string),
    options?: UseMediaQueryOptions
  ): boolean

  interface ColorBg {
    color: string
    background: string
  }
  interface ThemeVariant {
    name: string
    palette: {
      mode: "light" | "dark"
      colors: CustomColors
      primary: PaletteColorOptions
      secondary: PaletteColorOptions
      background: {
        default: string
        paper: string
        welcome: string
      }
      hraPlanDesign: {
        background: string
        backgroundChecked: string
      }
      stepper: {
        completed: string
        warning: string
        iconRoot: string
      }
    }
    header: ColorBg & {
      search: {
        color: string
      }
      indicator: {
        background: string
      }
    }
    footer: ColorBg
    navbar: ColorBg
    sidebar: ColorBg & {
      hover: string
      hoverText: string
      hoverBackground: string
      minHeight: string
      svg: {
        fontSize: string
        width: string
        height: string
      }
      header: ColorBg & {
        brand: {
          color: string
        }
      }
      footer: ColorBg & {
        online: {
          background: string
        }
      }
      badge: ColorBg
    }
    menu: ColorBg & {
      hover: ColorBg
    }
  }

  interface Theme extends ThemeVariant {}
}

const defaultVariant: ThemeVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: "light",
    primary: {
      main: takeCommandPrimary.main,
      contrastText: "#FFF",
    },
    secondary: {
      main: takeCommandSecondary.main,
      contrastText: "#FFF",
    },
    background: {
      default: colors.regionGray,
      paper: "#FFF",
      welcome: "#31A5821A",
    },
    hraPlanDesign: {
      background: "#E0E0E0",
      backgroundChecked: "rgba(49, 165, 130, 0.3)",
    },
    stepper: {
      completed: "#00796B",
      warning: "#F44336",
      iconRoot: "#eaeaf0",
    },
    colors,
  },
  header: {
    color: colors.darkBody,
    background: "#FFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: takeCommandPrimary.main,
    },
  },
  footer: {
    color: "#27322F",
    background: "#FFF",
  },
  navbar: {
    color: colors.darkBody,
    background: colors.boneChilling,
  },
  sidebar: {
    color: "#27322F",
    background: "#FFF",
    hover: colors.lightBlueShade,
    hoverText: colors.darkBody,
    hoverBackground: colors.cultured,
    minHeight: "48px",
    svg: {
      fontSize: "1.25rem",
      width: "1.5rem",
      height: "1.5rem",
    },
    header: {
      color: grey[200],
      background: "inherit",
      brand: {
        color: colors.white,
      },
    },
    footer: {
      color: grey[200],
      background: "#FFF",
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#FFF",
      background: takeCommandPrimary.main,
    },
  },
  menu: {
    color: colors.darkBody,
    background: colors.white,
    hover: {
      color: colors.darkBody,
      background: colors.lightTealShade,
    },
  },
}

const darkVariant = merge(defaultVariant, {
  name: THEMES.DARK,
  palette: {
    mode: "dark",
    primary: {
      main: takeCommandPrimary[600],
      contrastText: "#FFF",
    },
    background: {
      default: "#1B2635",
      paper: "#233044",
    },
    text: {
      primary: "rgba(255, 255, 255, 0.95)",
      secondary: "rgba(255, 255, 255, 0.5)",
    },
    colors: {
      boneChilling: "#233044",
    },
  },
  header: {
    color: grey[300],
    background: "#233044",
    search: {
      color: grey[200],
    },
  },
  footer: {
    color: grey[300],
    background: "#233044",
  },
  navbar: {
    color: grey[300],
    background: "#233044",
  },
  sidebar: {
    hoverText: colors.white,
  },
})

const lightVariant: ThemeVariant = merge(defaultVariant, {
  name: THEMES.LIGHT,
  palette: {
    mode: "light",
  },
  header: {
    color: grey[200],
    background: takeCommandPrimary[800],
    search: {
      color: grey[100],
    },
    indicator: {
      background: red[700],
    },
  },
  sidebar: {
    color: grey[900],
    background: "#FFF",
    header: {
      color: "#FFF",
      background: takeCommandPrimary[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: grey[800],
      background: "#F7F7F7",
      online: {
        background: green[500],
      },
    },
  },
})

const blueVariant: ThemeVariant = merge(defaultVariant, {
  name: THEMES.BLUE,
  palette: {
    mode: "light",
  },
  sidebar: {
    color: "#FFF",
    background: blue[700],
    header: {
      color: "#FFF",
      background: blue[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: blue[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
})

const greenVariant: ThemeVariant = merge(defaultVariant, {
  name: THEMES.GREEN,
  palette: {
    primary: {
      main: green[800],
      contrastText: "#FFF",
    },
    secondary: {
      main: green[500],
      contrastText: "#FFF",
    },
  },
  header: {
    indicator: {
      background: green[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: green[700],
    header: {
      color: "#FFF",
      background: green[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: green[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
})

const indigoVariant: ThemeVariant = merge(defaultVariant, {
  name: THEMES.INDIGO,
  palette: {
    primary: {
      main: indigo[600],
      contrastText: "#FFF",
    },
    secondary: {
      main: indigo[400],
      contrastText: "#FFF",
    },
  },
  header: {
    indicator: {
      background: indigo[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: indigo[700],
    header: {
      color: "#FFF",
      background: indigo[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: indigo[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
})

export const variants = [defaultVariant, darkVariant, lightVariant, blueVariant, greenVariant, indigoVariant]
