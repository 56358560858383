import { LoadingContentArea } from "@/components/LoadingContentArea"
import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { StyledCard } from "@/components/StyledCard"
import { BaseTable, OrderByItemType } from "@/components/Table/Table"
import { appConfig } from "@/config"
import { TABLE_CELL_PADDING } from "@/constants"
import { TcHubGuard } from "@/features/Auth/guards/TcHubGuard"
import { useGetHraPlan } from "@/features/CreateCompany/components/Steps/Setup/PlanSetup/planSetupService"
import { HraPlanModel } from "@/features/CreateCompany/components/Steps/Setup/PlanSetup/planSetupTypes"
import { CustomClasses } from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/CustomClasses"
import { useGetCompany } from "@/features/CreateCompany/components/Steps/Setup/setupService"
import { CompanyModel } from "@/features/CreateCompany/createCompanyEndpoints"
import { PAYROLL_REPORT, RECONCILIATION_PAYROLL_REPORT } from "@/features/Documents/documentsConstants"
import { useCompanyDocDownloadUrl, useCompanyDocuments } from "@/features/Documents/documentsService"
import {
  useGetCompanyOnboardingStatus,
  useGetEmployerOnboardingStatuses,
} from "@/features/EmployerOnboarding/employerOnboardingService"
import { useGetAdmins } from "@/features/People/peopleService"
import { useGetBillingCustomerId } from "@/features/Settings/hooks/useGetBillingCustomerId"
import { StripeId } from "@/features/Settings/types/paymentTypes"
import { extractMonthYear, formatDateToMmDdYyyy } from "@/utils/dates"
import { DATE_FORMAT_MONTH_DAY_YEAR, toTitleCase, transformDate } from "@/utils/formatting"
import { Uuid } from "@/utils/types"
import { EditOutlined, KeyboardArrowLeft } from "@mui/icons-material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Link,
  TableCell,
  Typography,
} from "@mui/material"
import { constant, noop } from "lodash"
import { useEffect, useMemo, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate, useParams } from "react-router-dom"
import { AdminUsersTableHeader, PayrollReportsTableHeader } from "../../tcHubTypes"
import { getStatusValue } from "../TcHubCompaniesTable"
import { TcHubCompanyDocuments } from "./TcHubCompanyDocuments"
import { useUpdateClasses } from "./useUpdateClasses"

const headersAdminUsers: AdminUsersTableHeader[] = [
  { id: "name", label: "Name", sortable: true, field: "name", alignment: "left" },
  {
    id: "email",
    label: "Email",
    field: row => row.email,
    sortable: true,
    alignment: "left",
  },
  {
    id: "phone",
    label: "Phone #",
    sortable: true,
    field: row => row.phoneNumber,
    alignment: "left",
  },
]

const headersPayrollReports: PayrollReportsTableHeader[] = [
  {
    id: "companyName",
    label: "Company Name",
    sortable: true,
    alignment: "left",
  },
  { id: "timePeriod", label: "Time Period", sortable: true, alignment: "left" },
  { id: "documentType", label: "Type", sortable: true, alignment: "left" },
  {
    id: "download",
    label: "Download",
    alignment: "left",
  },
]

interface CompanySectionProps {
  company: CompanyModel | null
  stripeId: StripeId | null
}

const CompanySection = ({ company, stripeId }: CompanySectionProps) => {
  const { companyId } = useParams<{ companyId: string }>()
  const { data } = useGetEmployerOnboardingStatuses(companyId!)
  const todayDate = new Date()
  const formattedDate = formatDateToMmDdYyyy(todayDate)
  const inviteDateEmailObject = data?.find(item => item.step === "INVITE_DATE_SET")

  const inviteDateStatus = inviteDateEmailObject?.statusValue
    ? formatDateToMmDdYyyy(inviteDateEmailObject.statusValue)
    : formattedDate

  const stripeIdLink = appConfig.isProduction
    ? `https://dashboard.stripe.com/customers/${stripeId}`
    : `https://dashboard.stripe.com/test/customers/${stripeId}`

  return (
    <StyledCard>
      <Grid container spacing={6} data-qa="tc-hub-company-profile-details">
        <Grid item xs={12}>
          <Typography variant="h5">Company Details</Typography>
          <Divider sx={{ mt: 4 }} />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa="company-detail-name">
            Company Name:
          </Typography>
          <Typography variant="body1">{company?.companyInfo.companyName ?? ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa="company-detail-business-structure">
            Business Structure:
          </Typography>
          <Typography variant="body1">{company?.companyInfo.companyType ?? ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa="company-detail-ein">
            EIN:
          </Typography>
          <Typography variant="body1">{company?.companyInfo.ein ?? ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa="company-detail-industry">
            Industry:
          </Typography>
          <Typography variant="body1">{company?.companyInfo.industry ?? ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa="company-detail-industry">
            Invitation email:
          </Typography>
          <Typography variant="body1">{inviteDateStatus ?? ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa="company-detail-stripe-id">
            Stripe ID:
          </Typography>
          <Typography variant="body1">
            {stripeId && (
              <Link target="_blank" href={stripeIdLink}>
                {stripeId}
              </Link>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa="company-detail-address">
            Company Address:
          </Typography>
          <Typography variant="body1">{company?.companyAddress.streetAddress ?? ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa="company-detail-suite">
            Suite/Apt:
          </Typography>
          <Typography variant="body1">{company?.companyAddress.suiteApt ?? ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa="company-detail-city">
            City:
          </Typography>
          <Typography variant="body1">{company?.companyAddress.city ?? ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa="company-detail-zip">
            ZIP code:
          </Typography>
          <Typography variant="body1">{company?.companyAddress.zip ?? ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1bold" data-qa="company-detail-state">
            State:
          </Typography>
          <Typography variant="body1">{company?.companyAddress.state ?? ""}</Typography>
        </Grid>
      </Grid>
    </StyledCard>
  )
}

interface HraPlanSectionProps {
  hraPlan: HraPlanModel | null
  company: CompanyModel | null
}

const HraPlanSection = ({ hraPlan, company }: HraPlanSectionProps) => (
  <StyledCard>
    <Grid container spacing={6} data-qa="tc-hub-hra-plan-details">
      <Grid item xs={12}>
        <Typography variant="h5">HRA Plan Details</Typography>
        <Divider sx={{ mt: 4 }} />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" data-qa="hra-plan-detail-package-name">
          Package Name:
        </Typography>
        <Typography variant="body1">{hraPlan?.packageName ?? ""}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" data-qa="company-detail-business-structure">
          Hra Start Date:
        </Typography>
        <Typography variant="body1">
          {hraPlan?.hraStartDate ? transformDate(hraPlan.hraStartDate, DATE_FORMAT_MONTH_DAY_YEAR) : ""}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" data-qa="company-detail-industry">
          Report Date:
        </Typography>
        <Typography variant="body1">{hraPlan?.dayOfMonth ?? ""}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" data-qa="company-detail-stripe-id">
          Report Frequency:
        </Typography>
        <Typography variant="body1">{hraPlan?.statementFrequency ?? ""}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" data-qa="company-detail-suite">
          Annual or Quarterly Compliance:
        </Typography>
        <Typography variant="body1">{hraPlan?.complianceFrequency ?? ""}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1bold" data-qa="company-detail-suite">
          Show AutoPay Setup:
        </Typography>
        <Typography variant="body1">{company?.companyInfo.autoPayAvailableOption ? "Yes" : "No"}</Typography>
      </Grid>
    </Grid>
  </StyledCard>
)

const defaultOrder = { headCellId: "name", direction: "asc" } as const

interface AdminTableProps {
  companyId: Uuid
}

export const AdminTable = ({ companyId }: AdminTableProps) => {
  const { data: admins, isLoading } = useGetAdmins(companyId)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [orderBy, setOrderBy] = useState<OrderByItemType[]>([defaultOrder])

  return (
    <StyledCard>
      <Typography variant="h5">Admin Details</Typography>
      <Divider sx={{ mt: 4 }} />
      {isLoading ? (
        <SkeletonTableLoader
          data-qa="skeleton-table-loader-admins"
          headerTitles={headersAdminUsers.map(cell => `${cell.label}`)}
          bodyRowsCount={3}
        />
      ) : (
        <BaseTable
          rows={admins ?? []}
          selected={[]}
          searchCriteria=""
          onToggleSelect={noop}
          onToggleSelectAll={noop}
          onPageChange={changedPage => setPage(changedPage)}
          onRowsPerPageChange={rows => setRowsPerPage(rows)}
          uniqueIdSelector={constant("")}
          headCells={headersAdminUsers ?? []}
          rowsPerPage={rowsPerPage}
          page={page}
          fullWidth
          orderBy={orderBy}
          onToggleOrderBy={headId =>
            setOrderBy(state => {
              const result = [] as OrderByItemType[]

              if (state && state.length > 0 && state[0].headCellId === headId) {
                result.push({
                  headCellId: headId,
                  direction: state[0].direction === "asc" ? "desc" : "asc",
                })
              } else {
                result.push({ headCellId: headId, direction: "asc" })
              }
              if (defaultOrder.headCellId !== headId) {
                result.push(defaultOrder)
              }

              return result
            })
          }
        >
          {({ row }) => (
            <>
              <TableCell>
                <Typography variant="body1" data-qa="admin-name" sx={TABLE_CELL_PADDING}>
                  {row.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                  {row.email}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                  {toTitleCase(row.phoneNumber ?? "")}
                </Typography>
              </TableCell>
            </>
          )}
        </BaseTable>
      )}
    </StyledCard>
  )
}

interface ClassSectionProps {
  currentHraPlan: HraPlanModel
}

const ClassSection = ({ currentHraPlan }: ClassSectionProps) => {
  const { customClasses, isUpdatingClasses, updateClasses, deleteClasses } = useUpdateClasses(currentHraPlan)

  return (
    <StyledCard sx={{ p: 4 }}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon data-qa="expand-classes" />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h5">Classes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CustomClasses
            customClasses={customClasses}
            createCustomClass={updateClasses}
            deleteCustomClasses={deleteClasses}
            isUpdatingClasses={isUpdatingClasses}
            isAssisted
          />
        </AccordionDetails>
      </Accordion>
    </StyledCard>
  )
}

interface PayRollSectionProps {
  company: CompanyModel
  companyId: string
}

const PayRollSection = ({ company, companyId }: PayRollSectionProps) => {
  const [expanded, setExpanded] = useState(false)
  const { data: companyDocuments, isLoading: isCompanyDocumentsLoading } = useCompanyDocuments(
    companyId as Uuid,
    expanded
  )
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | undefined>()
  const { data: url } = useCompanyDocDownloadUrl(companyId as Uuid, selectedDocumentId, expanded, false)

  useEffect(() => {
    if (url) {
      window.open(url, "_blank")
      setSelectedDocumentId(undefined)
    }
  }, [url])

  const handleDownloadPayrollReport = (documentId: string) => {
    setSelectedDocumentId(documentId)
  }

  const payrollReports = useMemo(
    () =>
      companyDocuments?.filter(
        doc => doc.documentType === PAYROLL_REPORT || doc.documentType === RECONCILIATION_PAYROLL_REPORT
      ) ?? [],
    [companyDocuments]
  )

  const renderDownloadButton = (documentId: string) => {
    if (selectedDocumentId === documentId) {
      return <CircularProgress size={24} />
    }
    return <FileDownloadOutlinedIcon />
  }

  const renderTableContent = () => {
    if (isCompanyDocumentsLoading) {
      return (
        <SkeletonTableLoader
          data-qa="skeleton-table-loader-payroll-reports"
          headerTitles={headersPayrollReports.map(cell => `${cell.label}`)}
          bodyRowsCount={4}
        />
      )
    }

    if (payrollReports.length === 0) {
      return (
        <Typography variant="body1" textAlign="center" color="text.secondary" py={4} data-qa="no-payroll-reports">
          No payroll reports available
        </Typography>
      )
    }

    return (
      <BaseTable
        rows={payrollReports}
        selected={[]}
        searchCriteria=""
        onToggleSelect={noop}
        onToggleOrderBy={noop}
        onToggleSelectAll={noop}
        onPageChange={noop}
        onRowsPerPageChange={noop}
        uniqueIdSelector={constant("")}
        headCells={headersPayrollReports ?? []}
        rowsPerPage={25}
        page={0}
        fullWidth
        orderBy={[{ headCellId: "", direction: "asc" }]}
        hidePagination
      >
        {({ row }) => (
          <>
            <TableCell>
              <Typography data-qa="company-name" sx={TABLE_CELL_PADDING}>
                {company?.companyInfo.companyName ?? ""}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography data-qa="month-year" sx={TABLE_CELL_PADDING}>
                {extractMonthYear(row, payrollReports)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography data-qa="document-type" sx={TABLE_CELL_PADDING}>
                {toTitleCase(row.documentType ?? "")}
              </Typography>
            </TableCell>
            <TableCell>
              <IconButton
                data-qa="payroll-report-download"
                onClick={() => handleDownloadPayrollReport(row.documentId ?? "")}
                aria-label="View"
                disabled={selectedDocumentId === row.documentId}
              >
                {renderDownloadButton(row.documentId ?? "")}
              </IconButton>
            </TableCell>
          </>
        )}
      </BaseTable>
    )
  }

  return (
    <StyledCard>
      <Accordion expanded={expanded} onChange={(_, isExpanded) => setExpanded(isExpanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon data-qa="expand-payroll" />}
          aria-controls="payroll-content"
          id="payroll-header"
        >
          <Typography variant="h5">Payroll Reports</Typography>
        </AccordionSummary>
        <AccordionDetails>{renderTableContent()}</AccordionDetails>
      </Accordion>
    </StyledCard>
  )
}

interface Params extends Record<string, string | undefined> {
  companyId: Uuid
}

export const TcHubCompanyProfilePage = () => {
  const { companyId } = useParams<Params>()
  const { data: company, isLoading } = useGetCompany(companyId)
  const { data: onboardingStatuses } = useGetCompanyOnboardingStatus(companyId!)
  const { data: hraPlans } = useGetHraPlan(companyId)

  const navigate = useNavigate()

  const currentHraPlan = useMemo(
    () => ({
      ...(hraPlans ? hraPlans[0] : ({ id: "" } as unknown as HraPlanModel)),
      packageName: getStatusValue(onboardingStatuses, "packageName"),
    }),
    [hraPlans, onboardingStatuses]
  )

  const { billingProviderCustomerId } = useGetBillingCustomerId(companyId)

  return isLoading ? (
    <LoadingContentArea data-qa="tc-hub-company-profile-page-loading" />
  ) : (
    <TcHubGuard requiredPermissions={["tc_hub_companies"]}>
      <Grid container data-qa="tc-hub-company-profile-page" direction="column">
        <Helmet title="TC Hub Company Profile" />
        <Grid item>
          <Button
            data-qa="back-button"
            type="submit"
            color="inherit"
            onClick={() => navigate(-1)}
            startIcon={<KeyboardArrowLeft />}
            sx={{ mb: 3 }}
          >
            Back
          </Button>
        </Grid>
        <Grid container justifyContent="space-between">
          <Typography variant="h1" gutterBottom display="inline">
            Company Information
          </Typography>
          <Grid item>
            <Button
              data-qa="edit-company-button"
              variant="contained"
              onClick={() => navigate(`edit`)}
              startIcon={<EditOutlined />}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <CompanySection company={company!} stripeId={billingProviderCustomerId} />
        <HraPlanSection hraPlan={currentHraPlan} company={company!} />
        <AdminTable companyId={companyId!} />
        <ClassSection currentHraPlan={currentHraPlan} />
        <PayRollSection company={company!} companyId={companyId!} />
        <TcHubCompanyDocuments companyId={companyId!} />
      </Grid>
    </TcHubGuard>
  )
}
