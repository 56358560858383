import { CheckBoxGroupCard } from "@/components/CheckBoxGroupCard"
import { Grid } from "@mui/material"

interface MultiSelectClassSectionProps {
  title: string
  formName: string
  handleChange: (field: string, value: any) => void
  elements: { title: string; value: string }[]
  errors?: { [key: string]: string }
}

export const MultiSelectClassSection = ({
  title,
  formName,
  handleChange,
  elements,
  errors = {},
}: MultiSelectClassSectionProps) => (
  <Grid item xs={12}>
    <CheckBoxGroupCard
      formName={formName}
      handleChange={handleChange}
      elements={elements}
      error={false}
      label={title}
      labelVariant="h6"
    />
  </Grid>
)
