import { Box } from "@mui/material"
import { ReactNode } from "react"
import { SidebarNav } from "./SidebarNav"
import { SidebarItem } from "./types"

export interface SidebarProps {
  items: SidebarItem[]
  open?: boolean
  isViewingTcHub?: boolean
  children?: ReactNode
}

export const Sidebar = ({ items, isViewingTcHub, children, ...props }: SidebarProps) => (
  <Box sx={{ height: "100%", px: 3 }}>
    <SidebarNav items={items} />
    {children}
  </Box>
)
