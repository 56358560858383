import { useRoleStore } from "@/features/Auth/roleStore"
import { ADMINISTRATOR_EXTERNAL_ROLE_ID } from "@/features/People/peopleConstants"
import { createDataQa } from "@/utils/dataQa"
import { KeyboardArrowLeft, OpenInNewOutlined } from "@mui/icons-material"
import { Box, Button, Card, CardContent, Drawer, Icon, List, ListItem, Toolbar, Typography } from "@mui/material"

interface ResourceCenterDrawerProps {
  open: boolean
  onClose: () => void
}

const adminResourceItems = [
  {
    title: "Admin Onboarding Guide",
    link: "https://www.takecommandhealth.com/hubfs/1%20Documents/HRA%20Hub%20Launch/Admin%20Onboarding%20Guide%20-%20HRA%20Hub.pdf",
  },
  {
    title: "Enrollment Explainer (AutoPay)",
    link: "https://www.takecommandhealth.com/hubfs/1%20Documents/HRA%20Hub%20Launch/HRA%20Hub%20-%20Enrollment%20Explainer_AutoPay_EE.pdf",
  },
  {
    title: "Enrollment Explainer (Non-AutoPay)",
    link: "https://www.takecommandhealth.com/hubfs/1%20Documents/HRA%20Hub%20Launch/HRA%20Hub%20-%20Enrollment%20Explainer_NonAutoPay_EE.pdf",
  },
  {
    title: "AutoPay Explainer",
    link: "https://www.takecommandhealth.com/hubfs/1%20Documents/AutoPay/TC_ICHRA%20AutoPay_2024.pdf",
  },
  {
    title: "Completing AutoPay Application",
    link: "https://takecommandhealth.sharepoint.com/sites/TakeCommandCentral/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FTakeCommandCentral%2FShared%20Documents%2FMarketing%2FDocuments%20and%20Collateral%2FOne%2DPagers%2F%5FAutoPay%5F%2FAutopay%20Application%20Instructions%5FER%5FLegacy%2Epdf&viewid=f130ddce%2D7a55%2D47f7%2D8afb%2D31fb2dc95364&parent=%2Fsites%2FTakeCommandCentral%2FShared%20Documents%2FMarketing%2FDocuments%20and%20Collateral%2FOne%2DPagers%2F%5FAutoPay%5F",
  },
]

const employeeResourceItems = [
  {
    title: "HRA Hub Shopping Guide",
    link: "https://www.takecommandhealth.com/hubfs/1%20Documents/HRA%20Hub%20Launch/HRA%20Hub%20Shopping%20Guide.pdf",
  },
  {
    title: "Multi-Factor Authentication",
    link: "https://www.takecommandhealth.com/hubfs/1%20Documents/HRA%20Hub%20Launch/HRA%20Hub%20-%20MFA%20-%20Login.pdf",
  },
  {
    title: "How to Upload Proof of Coverage",
    link: "https://www.takecommandhealth.com/hubfs/1%20Documents/HRA%20Hub%20Launch/HRA%20Hub%20-%20POC%20Upload%20.pdf",
  },
  {
    title: "Insurance 101",
    link: "https://takecommandhealth.sharepoint.com/sites/TakeCommandCentral/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FTakeCommandCentral%2FShared%20Documents%2FMarketing%2FDocuments%20and%20Collateral%2FOne%2DPagers%2FOnboarding%5FExplainer%20%2D%20EEs%2FInsurance%20101%20%28new%29%2Epdf&viewid=f130ddce%2D7a55%2D47f7%2D8afb%2D31fb2dc95364&parent=%2Fsites%2FTakeCommandCentral%2FShared%20Documents%2FMarketing%2FDocuments%20and%20Collateral%2FOne%2DPagers%2FOnboarding%5FExplainer%20%2D%20EEs",
  },
]

export const ResourceCenterDrawer = ({ open, onClose }: ResourceCenterDrawerProps) => {
  const { activeRole } = useRoleStore()
  const isAdmin = activeRole === ADMINISTRATOR_EXTERNAL_ROLE_ID

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: { xs: "100%", sm: 400 },
          p: 3,
        },
      }}
      data-qa={createDataQa("resource-center-drawer")}
    >
      <Toolbar />
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
        <Button startIcon={<KeyboardArrowLeft />} color="inherit" variant="text" onClick={onClose}>
          Back
        </Button>
      </Box>
      <Typography variant="h2tiempos" gutterBottom ml={3}>
        Resource Center
      </Typography>

      {isAdmin && (
        <Card sx={{ mb: 12 }}>
          <CardContent>
            <Typography variant="h6" mb={4}>
              Admin Resources
            </Typography>
            <Box>
              <List disablePadding>
                {adminResourceItems.map(item => (
                  <ListItem
                    key={item.title}
                    disableGutters
                    component="a"
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      textDecoration: "none",
                      color: "inherit",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="body1" color="inherit">
                      {item.title}
                    </Typography>
                    <Icon>
                      <OpenInNewOutlined />
                    </Icon>
                  </ListItem>
                ))}
              </List>
            </Box>
          </CardContent>
        </Card>
      )}

      <Card>
        <CardContent>
          <Typography variant="h6" mb={4}>
            Employee Resources
          </Typography>
          <Box>
            <List disablePadding>
              {employeeResourceItems.map(item => (
                <ListItem
                  key={item.title}
                  disableGutters
                  component="a"
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textDecoration: "none",
                    color: "inherit",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="body1" color="inherit">
                    {item.title}
                  </Typography>
                  <Icon>
                    <OpenInNewOutlined />
                  </Icon>
                </ListItem>
              ))}
            </List>
          </Box>
        </CardContent>
      </Card>
    </Drawer>
  )
}
