import { BasePlanStructure } from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/PlanStructure"
import { useCreateCompanyAccount, useCreateCompanyHraPlan } from "@/features/CreateCompany/CreateCompanyProvider"
import { PurchaseHraStepProps } from "@/features/CreateCompany/pages/CreateCompany"

export const TcHubPlanStructure = ({ stepData }: PurchaseHraStepProps) => {
  const { company } = useCreateCompanyAccount()
  const { hraPlan } = useCreateCompanyHraPlan()

  return (
    <BasePlanStructure
      stepData={stepData}
      companyId={company.id}
      hraPlan={hraPlan}
      nextStep="/admin/companies"
      previousStep="/admin/create-company/plan-setup"
      isAssisted
    />
  )
}
