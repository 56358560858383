import { AppContentWrapper } from "@/components/AppContentWrapper"
import { Footer } from "@/components/Footer"
import { Navbar } from "@/components/Navbar/Navbar"
import { RootWrapper } from "@/components/RootWrapper"
import { AuthGuard } from "@/features/Auth/guards/AuthGuard"
import { Outlet } from "react-router-dom"

export const BenefitsElectionWithoutGuardLayout = () => (
  <AuthGuard>
    <RootWrapper>
      <AppContentWrapper>
        <Navbar />
        <Outlet />
        <Footer />
      </AppContentWrapper>
    </RootWrapper>
  </AuthGuard>
)
