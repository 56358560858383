import { Box, Grid, Switch, Typography } from "@mui/material"
import { AddPeopleStepProps } from "../addPersonValidations"
import { ReactComponent as BenefitOffIcon } from "../assets/BenefitOff.svg"
import { ReactComponent as BenefitOnIcon } from "../assets/BenefitOn.svg"
import { STEPS } from "../types"
import { AddPersonStep } from "./AddPersonStep"

/**
 * Confirmation well done content
 */
export const ConfirmBenefitsStep = ({ onClose, onBack, onNextStep, values, handleChange }: AddPeopleStepProps) => (
  <AddPersonStep
    onClose={onClose}
    onBack={onBack}
    onContinue={() => onNextStep(values.isTcHub ? STEPS.CONFIRM_COMPANY : STEPS.ABOUT_PERSON_FORM)}
  >
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        mt: 10,
        gap: 2,
      }}
    >
      <Box pl={12}>{values.benefitEligible ? <BenefitOnIcon /> : <BenefitOffIcon />}</Box>

      <Typography variant="h5" data-qa="is-benefit-eligible-step">
        Is the user benefit eligible?
      </Typography>
      <Typography>
        This means they qualify to participate in HRA benefits based on your company’s eligibility guidelines
      </Typography>

      <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
        <Typography variant="body2">No</Typography>
        <Switch checked={values.benefitEligible} name="benefitEligible" onChange={handleChange} />
        <Typography variant="body2">Yes</Typography>
      </Grid>
    </Box>
  </AddPersonStep>
)
