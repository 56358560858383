import styled from "@emotion/styled"
import { ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material"
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined"
import { Chip, Collapse, ListItemButton, ListItemProps, ListItemText, SvgIconProps, Typography } from "@mui/material"
import { ComponentType, forwardRef, useEffect, useState } from "react"
import { NavLink, NavLinkProps, useNavigate } from "react-router-dom"

const CustomRouterLink = forwardRef<any, NavLinkProps>((props, ref) => (
  <div ref={ref}>
    <NavLink {...props} />
  </div>
))

interface ItemProps {
  activeclassname?: string
  onClick?: () => void
  to?: string
  component?: typeof NavLink
  depth: number
}

// FUTURE: Refactor away this use of `styled` utility
const Item = styled(ListItemButton)<ItemProps>`
  height: auto;
  padding-left: ${props => props.theme.spacing(props.depth && props.depth > 0 ? 12 * props.depth + 4 : 4)};
  padding-right: ${props => props.theme.spacing(4)};
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  font-size: 20px;
  border-radius: 8;

  svg {
    font-size: 1.25rem;
    width: 1.5rem;
    height: 1.5rem;

    &.tch-nav-action {
      font-size: 2.5rem;
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &:hover {
    background: ${({ theme }) => theme.sidebar.hover};
    background: ${({ theme }) => theme.sidebar.hoverBackground};
  }

  &.${props => props.activeclassname} {
    background-color: ${({ theme }) => theme.sidebar.hover};
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }
`

// FUTURE: Refactor away this use of `styled` utility
const Badge = styled(Chip)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  background: ${({ theme }) => theme.sidebar.badge.background};
  z-index: 1;

  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${({ theme }) => theme.sidebar.badge.color};
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`

export interface SidebarNavListItemProps extends ListItemProps {
  depth: number
  href: string
  icon?: ComponentType<SvgIconProps & { activeclassname?: string }>
  badge?: string
  open?: boolean
  title: string
  complete?: boolean
  disabled?: boolean
  parentLink?: boolean
  disableDropdown?: boolean
}

interface NavActionProps {
  open?: boolean
  complete?: boolean
}

const NavAction = ({ open, complete }: NavActionProps) => {
  const Icon = open ? ExpandLessOutlined : ExpandMoreOutlined

  const iconProps = {
    className: "tch-nav-action",
    color: complete ? "primary" : "inherit",
  } as const

  return <Icon {...iconProps} />
}

export const SidebarNavListItem = ({
  title,
  href,
  depth = 0,
  children,
  icon: Icon,
  badge,
  open: openProp = false,
  complete = false,
  disabled = false,
  parentLink = false,
  disableDropdown = false,
}: SidebarNavListItemProps) => {
  const hasIcon = !!Icon
  const [open, setOpen] = useState(openProp)

  const handleToggle = () => {
    setOpen(state => !state)
  }

  const navigate = useNavigate()

  useEffect(() => {
    if (openProp) {
      setOpen(openProp)
    }
  }, [openProp])

  if (children) {
    return (
      <>
        <Item
          depth={depth}
          onClick={() => {
            if (parentLink) {
              navigate(href)
            } else {
              handleToggle()
            }
          }}
          disabled={disabled}
          data-qa={title.toLowerCase().replace(/\s/g, "-")}
        >
          {hasIcon && <Icon />}
          <ListItemText
            primary={
              <Typography
                sx={{
                  paddingX: hasIcon ? 6 : 0,
                  fontWeight: 500,
                  fontSize: "0.938rem",
                  letterSpacing: "0.2px",
                }}
              >
                {title}
                {badge && <Badge label={badge} />}
              </Typography>
            }
          />
          {!disableDropdown && <NavAction open={open} />}
        </Item>
        <Collapse in={open}>{children}</Collapse>
      </>
    )
  }

  return (
    <Item
      depth={depth}
      component={CustomRouterLink}
      to={href}
      activeclassname="active"
      disabled={disabled}
      data-qa={title.toLowerCase().replace(/\s/g, "-")}
    >
      {hasIcon && <Icon activeclassname="active" />}
      <ListItemText
        primary={
          <Typography
            sx={{
              paddingX: hasIcon ? 6.25 : 0,
              fontWeight: "inherit",
              fontSize: "0.938rem",
              letterSpacing: "0.1px",
            }}
          >
            {title}
            {badge && <Badge label={badge} />}
          </Typography>
        }
      />
      {complete && <CheckCircleOutlineOutlinedIcon color="primary" data-qa="complete-item-icon" />}
    </Item>
  )
}
