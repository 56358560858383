import { SidebarItem } from "@/components/Sidebar/types"
import { useLocation } from "react-router-dom"
import { reduceChildRoutes } from "./reduceChildRoutes"

export interface SidebarNavListProps {
  depth: number
  pages: SidebarItem[]
  isViewingTcHub?: boolean
}

export const SidebarNavList = ({ depth, pages }: SidebarNavListProps) => {
  const location = useLocation()
  const currentRoute = location.pathname

  const childRoutes = pages
    .filter(page => !page.hidden)
    // FUTURE: Remove this unsafe type cast
    .reduce((items, page) => reduceChildRoutes({ items, page, currentRoute, depth }), [] as JSX.Element[])

  return childRoutes
}
