import { CompanyModel } from "@/features/CreateCompany/createCompanyEndpoints"
import { createFuseFilterOptions } from "@/utils/createFuseFilterOptions"
import { Autocomplete, CircularProgress, SxProps, TextField, Typography } from "@mui/material"
import { isNil, size } from "lodash"
import { useAutoPayCompaniesInfo } from "../tcHubService"

interface CompanyAutopaySearchAutoCompleteProps {
  error?: string
  name: string
  handleBlur: any
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  touched: boolean
  value: CompanyModel | null
  sx?: SxProps
  dataQa?: string
  label?: string
  disabled?: boolean
}

const fuseFilterOptions = createFuseFilterOptions<CompanyModel>({
  includeMatches: true,
  ignoreLocation: true,
  threshold: 0.5,
  limit: 10,
  keys: ["companyInfo.companyName"],
})

// FUTURE: Consider creating a reusable component to centralize the search logic within forms
export const CompanyAutopaySearchAutoComplete = ({
  error,
  name,
  handleBlur,
  setFieldValue,
  touched,
  value: propsValue,
  sx,
  disabled,
  label = "Search Companies",
}: CompanyAutopaySearchAutoCompleteProps) => {
  const { data: options, isLoading: loading } = useAutoPayCompaniesInfo()

  return (
    <Autocomplete
      disabled={disabled}
      autoComplete
      forcePopupIcon={false}
      filterOptions={fuseFilterOptions}
      data-qa="company-search-autocomplete"
      getOptionLabel={option => `${option.companyInfo?.companyName ?? ""}`}
      loading={loading && size(options) === 0}
      isOptionEqualToValue={(option, newValue) => option.id === newValue.id}
      options={options ?? []}
      sx={sx}
      value={propsValue ?? null}
      onChange={(e, newValue) => {
        setFieldValue(name, newValue)
      }}
      renderOption={(props, option, { index }) => (
        <li {...props} key={index}>
          <Typography variant="body1">
            {option.companyInfo?.companyName + " (" + option.companyInfo.ein + ")"}
          </Typography>
        </li>
      )}
      renderInput={({ InputProps, ...params }) => (
        <TextField
          {...params}
          error={!isNil(error) && touched}
          helperText={touched && error && "Please search for a valid company name"}
          label={label}
          name={name}
          onBlur={handleBlur}
          placeholder="Search for a Company Name"
          required
          InputProps={{
            ...InputProps,
            endAdornment: loading ? (
              <CircularProgress color="inherit" size={20} sx={{ mx: 3 }} />
            ) : (
              InputProps.endAdornment
            ),
          }}
          inputProps={{ ...params.inputProps }}
        />
      )}
    />
  )
}
