import { PermissionGuard } from "@/features/Auth/guards/PermissionGuard"
import { hasMultipleRoles, useRoleStore } from "@/features/Auth/roleStore"
import { useAuth } from "@/features/Auth/useAuth"
import {
  ADMINISTRATOR_EXTERNAL_ROLE_ID,
  EMPLOYEE_EXTERNAL_ROLE_ID,
  getViewSelectableRole,
  isValidAdminRoleId,
} from "@/features/People/peopleConstants"
import { colors, takeCommandPrimary } from "@/theme/palette"
import { createDataQa, WithDataQa } from "@/utils/dataQa"
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined"
import BusinessIcon from "@mui/icons-material/Business"
import CheckIcon from "@mui/icons-material/Check"
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined"
import SwitchAccountOutlinedIcon from "@mui/icons-material/SwitchAccountOutlined"

import { useOnboardingStatuses } from "@/features/EmployerOnboarding/employerOnboardingService"
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuList,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material"
import { useQueryClient } from "@tanstack/react-query"
import { SyntheticEvent, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useIntercom } from "react-use-intercom"
import { ClearableSearchInput } from "../ClearableSearchInput/ClearableSearchInput"

export interface NavbarUserDropdownProps {
  hideProfileItem?: boolean
  associatedCompanies?: any[]
  activeCompanyId?: string
  isLoadingAssociatedCompanies?: boolean
  labelRole?: string
  isViewingTcHub?: boolean
  onCompanySelect?: (id: string) => void
}

interface UserGreetingProps {
  name?: string
  isViewingTcHub?: boolean
}

const UserGreeting = ({ name, isViewingTcHub }: UserGreetingProps) => (
  <Grid item sx={{ paddingLeft: 2 }}>
    <Typography variant="body1" sx={{ color: isViewingTcHub ? colors.white : colors.darkBody }}>
      Hello, {name}
    </Typography>
  </Grid>
)

const NavMenuItem = ({ className = "", ...props }: WithDataQa<MenuItemProps>) => (
  <MenuItem className={`${className} tch-menu-item`.trimStart()} {...props} />
)

interface CompanySwitcherProps {
  associatedCompanies: any[]
  activeCompanyId?: string
  isLoadingAssociatedCompanies?: boolean
  labelRole?: string
  closeMenu: () => void
  onCompanySelect?: (id: string) => void
}

const CompanySwitcher = ({
  associatedCompanies,
  activeCompanyId,
  isLoadingAssociatedCompanies,
  labelRole,
  closeMenu,
  onCompanySelect,
}: CompanySwitcherProps) => {
  const [searchInputValue, setSearchInputValue] = useState<string>("")
  const handleCompanySelect = async (companyId: string) => {
    await onCompanySelect?.(companyId)
    closeMenu()
    window.location.reload()
  }

  return (
    <MenuList>
      <Divider variant="middle" sx={{ mb: 4 }} />
      <ListItem sx={{ mt: 3 }}>
        <Typography variant="body1bold">Organizations</Typography>
      </ListItem>
      <ListItem>
        <ClearableSearchInput
          onChange={event => setSearchInputValue(event.target.value)}
          handleClearClick={() => setSearchInputValue("")}
          data-qa="account-search-input"
          sx={{ width: "100%" }}
          onKeyDown={e => e.stopPropagation()}
        />
      </ListItem>
      {isLoadingAssociatedCompanies ? (
        <>
          <Skeleton variant="text" sx={{ fontSize: "1rem", m: 4 }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem", m: 4 }} />
        </>
      ) : (
        associatedCompanies
          ?.filter(associatedCompany => associatedCompany.name?.toLowerCase().includes(searchInputValue.toLowerCase()))
          .map(associatedCompany => (
            <>
              <NavMenuItem
                key={associatedCompany.id}
                data-qa={createDataQa("avatar-switch-company")}
                onClick={() => handleCompanySelect(associatedCompany.id)}
              >
                {activeCompanyId === associatedCompany.id && (
                  <ListItemIcon sx={{ pr: 2 }}>
                    <CheckIcon />
                  </ListItemIcon>
                )}
                <ListItemText>
                  <Typography
                    sx={{
                      color: activeCompanyId === associatedCompany.id ? colors.darkBody : takeCommandPrimary.main,
                      fontWeight: activeCompanyId === associatedCompany.id ? 700 : null,
                    }}
                  >
                    {associatedCompany.name}
                    {labelRole}
                  </Typography>
                </ListItemText>
              </NavMenuItem>
              <Divider variant="middle" />
            </>
          ))
      )}
    </MenuList>
  )
}

export const NavbarUserDropdown = ({
  hideProfileItem,
  associatedCompanies,
  activeCompanyId,
  labelRole,
  onCompanySelect,
  isViewingTcHub,
}: NavbarUserDropdownProps) => {
  const { isOnboardingComplete } = useOnboardingStatuses()
  const [anchorMenu, setAnchorMenu] = useState<any>(null)
  const navigate = useNavigate()
  const { isLoggedIn, signOut, displayName, displayInitial } = useAuth()
  const { shutdown } = useIntercom()
  const queryClient = useQueryClient()
  const isAdminPage = location.pathname.startsWith("/admin")
  const { user } = useAuth()
  const roles = useMemo(() => user?.company?.roles ?? [], [user])
  const showRoleSwitch = hasMultipleRoles(roles) && !!user?.company?.employeeId && isOnboardingComplete
  const { activeRole, setActiveRole } = useRoleStore()

  useEffect(() => {
    // Compute the valid role based on the user's company roles
    const computedRole = roles.some(role => isValidAdminRoleId(role.roleId))
      ? ADMINISTRATOR_EXTERNAL_ROLE_ID
      : EMPLOYEE_EXTERNAL_ROLE_ID
    // Check if the activeRole is valid
    const isActiveRoleValid = roles.some(role => activeRole && getViewSelectableRole(role.roleId) === activeRole)
    // If the activeRole is different (or stale) compared to the computed role, update it.
    if (activeRole !== computedRole && !isActiveRoleValid) {
      setActiveRole(computedRole)
    }
  }, [activeRole, roles, setActiveRole])

  const toggleMenu = (event: SyntheticEvent) => {
    setAnchorMenu(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorMenu(null)
  }

  const handleSignOut = async () => {
    queryClient.clear()
    shutdown()
    useRoleStore.getState().resetActiveRole()
    await signOut()
    navigate("/sign-in", { replace: true })
  }

  return (
    <>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="medium"
          sx={{ borderRadius: "0.5rem" }}
        >
          <Avatar
            data-qa="header-avatar"
            sx={theme => ({
              color: isViewingTcHub ? theme.palette.colors.darkBody : theme.palette.colors.white,
              background: isViewingTcHub ? colors.white : theme.palette.primary.main,
            })}
          >
            {displayInitial}
          </Avatar>
          {isLoggedIn && <UserGreeting name={displayName} isViewingTcHub={isViewingTcHub} />}
        </IconButton>
      </Tooltip>
      <Menu id="menu-appbar" anchorEl={anchorMenu} open={!!anchorMenu} onClose={closeMenu} sx={{ width: "100%" }}>
        {isLoggedIn ? (
          <Box sx={{ minWidth: "18.65rem" }}>
            <ListItemText sx={{ pb: 2, pl: 4 }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography style={{ textAlign: "left", paddingRight: 12 }} variant="h5">
                    {displayName}
                    <br />
                    <Typography variant="caption">{labelRole}</Typography>
                  </Typography>
                </Grid>
                <PermissionGuard
                  internalOnly
                  in={[
                    "tc_hub_users",
                    "tc_hub_companies",
                    "tc_hub_autopay",
                    "tc_hub_compliance",
                    "tc_hub_carriers",
                    "tc_hub_site_settings",
                  ]}
                  matchType="some"
                >
                  {!isAdminPage ? (
                    <Grid item>
                      <Button
                        variant="outlined"
                        sx={{ mr: 4, fontWeight: 600 }}
                        size="small"
                        data-qa="tc-hub-link"
                        onClick={() => {
                          closeMenu()
                          navigate("/admin/users")
                        }}
                        startIcon={<AdminPanelSettingsOutlinedIcon />}
                      >
                        TC Hub
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item>
                      <Button
                        variant="outlined"
                        sx={{ mr: 4, fontWeight: 600 }}
                        size="small"
                        data-qa="exit-tc-hub-link"
                        onClick={() => {
                          closeMenu()
                          navigate("/")
                        }}
                        startIcon={<AdminPanelSettingsOutlinedIcon />}
                      >
                        Exit TC Hub
                      </Button>
                    </Grid>
                  )}
                </PermissionGuard>
              </Grid>
            </ListItemText>
            <Divider variant="middle" sx={{ mb: 2 }} />
            {!hideProfileItem && (
              <NavMenuItem
                data-qa={createDataQa("avatar-profile")}
                onClick={() => {
                  closeMenu()
                  navigate(
                    activeRole === ADMINISTRATOR_EXTERNAL_ROLE_ID ? "/company/settings?tab=company-profile" : "/profile"
                  )
                }}
                sx={{ color: takeCommandPrimary.main }}
              >
                <Box display="flex" width="100%" alignItems="center">
                  <ListItemIcon sx={{ minWidth: "1.75rem" }}>
                    {activeRole === ADMINISTRATOR_EXTERNAL_ROLE_ID ? (
                      <BusinessIcon color="primary" />
                    ) : (
                      <PersonOutlineOutlinedIcon color="primary" />
                    )}
                  </ListItemIcon>
                  <ListItemText>
                    {activeRole === ADMINISTRATOR_EXTERNAL_ROLE_ID ? "Company Profile" : "My Profile"}
                  </ListItemText>
                </Box>
              </NavMenuItem>
            )}
            {showRoleSwitch && (
              <NavMenuItem
                onClick={() => {
                  const newRole =
                    activeRole === ADMINISTRATOR_EXTERNAL_ROLE_ID
                      ? EMPLOYEE_EXTERNAL_ROLE_ID
                      : ADMINISTRATOR_EXTERNAL_ROLE_ID
                  setActiveRole(newRole)
                  closeMenu()
                  navigate("/")
                }}
                sx={{ color: takeCommandPrimary.main }}
                data-qa={createDataQa("role-switch-menu-item")}
              >
                <Box display="flex" width="100%" alignItems="center">
                  <ListItemIcon sx={{ minWidth: "1.75rem" }}>
                    <SwitchAccountOutlinedIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText>
                    Switch to {activeRole === ADMINISTRATOR_EXTERNAL_ROLE_ID ? "Employee View" : "Company View"}
                  </ListItemText>
                </Box>
              </NavMenuItem>
            )}
            {associatedCompanies && associatedCompanies.length > 1 && (
              <CompanySwitcher
                closeMenu={closeMenu}
                onCompanySelect={onCompanySelect}
                associatedCompanies={associatedCompanies}
                activeCompanyId={activeCompanyId}
              />
            )}
            <NavMenuItem
              className="tch-menu-item"
              data-qa={createDataQa("avatar-sign-out")}
              onClick={handleSignOut}
              sx={{ color: takeCommandPrimary.main, mt: 2 }}
            >
              <ListItemText>Sign out</ListItemText>
            </NavMenuItem>
          </Box>
        ) : (
          <NavMenuItem className="tch-menu-item" data-qa={createDataQa("signin")} onClick={() => navigate("/sign-in")}>
            Sign In
          </NavMenuItem>
        )}
      </Menu>
    </>
  )
}
