import { AmountTextFieldWithChips } from "@/components/AmountTextField"
import { RadioGroupCard } from "@/components/RadioGroupCard"
import { WAITING_PERIODS } from "@/features/CreateCompany/createCompanyConstants"
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { Button, Grid, Stack, Typography } from "@mui/material"
import { useFormikContext } from "formik"
import { useState } from "react"
import { AmountAgesTable } from "../../../common/AmountAgesTable"
import { PlanStructureFormValues } from "./planStructureTypes"

interface WaitingPeriodRadioCardProps {
  subsection?: string
  value: string
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

export const WaitingPeriodRadioCard = ({ subsection, value, setFieldValue }: WaitingPeriodRadioCardProps) => (
  <Grid item xs={12}>
    <RadioGroupCard
      formName={`plan-structure${subsection && "-" + subsection}`}
      name="waitingPeriod"
      value={value}
      handleChange={setFieldValue}
      elements={WAITING_PERIODS}
      label="Waiting Period"
      labelVariant="h6"
    />
  </Grid>
)

export const ReimbursementAmountInputs = () => {
  const [agesTableOpen, setAgesTableOpen] = useState(false)
  const { values, touched, errors, handleBlur, handleChange } = useFormikContext<PlanStructureFormValues>()

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Typography variant="h6" data-qa="varybyfamilysizeandage-reimbursement-amounts">
          Reimbursement amounts per month
        </Typography>
        <Typography variant="caption">
          Amounts entered for each group are total monthly costs for the entire group, not per person.
        </Typography>
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
          <Typography variant="caption">Here you can see your generated reimbursement roles by age:</Typography>
          <Button
            sx={{ color: "colors.darkBody" }}
            startIcon={agesTableOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            onClick={() => setAgesTableOpen(!agesTableOpen)}
          >
            Expand
          </Button>
        </Stack>
      </Grid>

      <AmountAgesTable agesTableOpen={agesTableOpen} isAgeAndFamily />

      <Grid item xs={12}>
        <AmountTextFieldWithChips
          touched={touched.employeeAmount!}
          errorString={errors.employeeAmount!}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.employeeAmount?.toString()}
          label="Employee - Age 21"
          name="employeeAmount"
          sx={{ mt: 0 }}
        />
      </Grid>
      <Grid item xs={12} mt={4}>
        <AmountTextFieldWithChips
          touched={touched.employeeAndSpouseAmount!}
          errorString={errors.employeeAndSpouseAmount!}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.employeeAndSpouseAmount?.toString()}
          label="Employee + Spouse"
          name="employeeAndSpouseAmount"
        />
      </Grid>
      <Grid item xs={12} mt={4}>
        <AmountTextFieldWithChips
          touched={touched.employeeAndChildrenAmount!}
          errorString={errors.employeeAndChildrenAmount!}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.employeeAndChildrenAmount?.toString()}
          label="Employee + Children"
          name="employeeAndChildrenAmount"
        />
      </Grid>
      <Grid item xs={12} mt={4}>
        <AmountTextFieldWithChips
          touched={touched.employeeAndSpouseAndChildrenAmount!}
          errorString={errors.employeeAndSpouseAndChildrenAmount!}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.employeeAndSpouseAndChildrenAmount?.toString()}
          label="Employee + Spouse + Children"
          name="employeeAndSpouseAndChildrenAmount"
        />
      </Grid>
    </Grid>
  )
}
