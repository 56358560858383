import { BaseLayout } from "@/features/Dashboard/components/BaseLayout"
import { ReactNode } from "react"
import { useTcHubMenuItems } from "../tcHubMenuItems"
interface TcHubLayoutProps {
  children?: ReactNode
  menuItems?: any[]
  isViewingTcHub?: boolean
}

export const TcHubLayout = ({ children, menuItems, isViewingTcHub }: TcHubLayoutProps) => {
  const tcHubMenuItems = useTcHubMenuItems()

  return (
    <BaseLayout menuItems={tcHubMenuItems} isViewingTcHub>
      {children}
    </BaseLayout>
  )
}
