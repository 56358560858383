import { FlowNavigationButtons } from "@/components/FlowNavigationButtons"
import { RadioGroupCard } from "@/components/RadioGroupCard"
import { SelectField } from "@/components/SelectField"
import { ClampedTextField } from "@/components/TextFields"
import { EXTERNAL_LINKS } from "@/constants"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { useCreateCompanyHraPlan } from "@/features/CreateCompany/CreateCompanyProvider"
import { Stepper } from "@/features/CreateCompany/components/Stepper"
import { currentDate } from "@/features/CreateCompany/components/Steps/Setup/PlanSetup/PlanSetup"
import {
  convertUnassistedHraPlanToFormValues,
  useCreateUnassistedHraPlan,
  useGetHraPlan,
  useUpdateUnassistedHraPlan,
} from "@/features/CreateCompany/components/Steps/Setup/PlanSetup/planSetupService"
import {
  CreateUnassistedHraPlanValues,
  HraPlanModel,
} from "@/features/CreateCompany/components/Steps/Setup/PlanSetup/planSetupTypes"
import { DashboardReturnConfirmation } from "@/features/CreateCompany/components/Steps/Setup/Setup"
import { PackageCardInfo } from "@/features/CreateCompany/components/common/PackageCardInfo"
import {
  GROWTH_ADP_PACKAGE_NAME,
  MAXIMUM_ELIGIBLE_EMPLOYEES,
  MAXIMUM_PARTICIPATING_EMPLOYEES,
  STARTER_PACKAGE_NAME,
} from "@/features/CreateCompany/createCompanyConstants"
import { useNotifications } from "@/services/notificationService"
import { takeCommandSecondary } from "@/theme/palette"
import { getNextSixMonthsStartDates, isDateWithinRange } from "@/utils/dates"
import { Uuid } from "@/utils/types"
import { UnassistedPlanSetupValidationSchema } from "@/utils/validations"
import { Grid, Link, Alert as MuiAlert, styled, Typography } from "@mui/material"
import { spacing } from "@mui/system"
import { Formik } from "formik"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { PurchaseHraStepProps } from "../Companies/AdpCompanyCreate"

const Alert = styled(MuiAlert)(spacing)

export const AdpPlanSetup = ({ stepData }: PurchaseHraStepProps) => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const currentCompany = getActiveCompany(user)
  //FUTURE Remove this unsafe type cast
  const companyId = currentCompany?.companyId as Uuid
  const { hraPlan, setCompanyHraPlan } = useCreateCompanyHraPlan()
  const [isLoading, setIsLoading] = useState(false)
  const { data: currentHraPlans } = useGetHraPlan(companyId)
  const currentHraPlan = currentHraPlans?.[0]
  const { mutateAsync: createHraPlan } = useCreateUnassistedHraPlan(companyId)
  const { mutateAsync: updateHraPlan } = useUpdateUnassistedHraPlan(companyId, currentHraPlan?.id)
  const [participatingEmployees, setParticipatingEmployees] = useState(0)
  const [eligibleEmployees, setEligibleEmployees] = useState(0)
  const [showAffordabilityModal, setShowAffordabilityModal] = useState(true)
  const { notify } = useNotifications("create-company-plan")

  return (
    <Formik
      initialValues={convertUnassistedHraPlanToFormValues(currentHraPlan ?? hraPlan)}
      enableReinitialize
      validationSchema={UnassistedPlanSetupValidationSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        setIsLoading(true)
        let successMessage = "Company Plan Creation Successful"
        let errorMessage = `Error creating plan for company ${currentCompany?.companyId}`

        try {
          const formValues: CreateUnassistedHraPlanValues = {
            ...values,
            hraStartDate: values.hraStartDate!,
            statementCreationDate: "1",
            eligibleEmployees: values.eligibleEmployees,
            participatingEmployees: values.participatingEmployees,
            isAutoPay: values.isAutoPay,
            ichra: values.ichra,
            packageName: values.isAutoPay ? GROWTH_ADP_PACKAGE_NAME : STARTER_PACKAGE_NAME,
            submit: values.submit,
          }

          if (currentHraPlan?.id) {
            successMessage = "Company Plan Update Successful"
            errorMessage = `Error updating plan for company ${currentCompany?.companyId}`
            await updateHraPlan(formValues)
            setCompanyHraPlan(currentHraPlan)
          } else {
            const createdHraPlan: HraPlanModel = await createHraPlan(formValues)

            setCompanyHraPlan(createdHraPlan)
          }
          notify(successMessage, "success")

          navigate("/adp/create-company/plan-structure")
        } catch (error: any) {
          const message = error.message || "Something went wrong. Please try again later."

          setStatus({ success: false })
          setErrors({ submit: message })
          setSubmitting(false)

          notify(errorMessage, "error")
        } finally {
          setIsLoading(false)
        }
      }}
    >
      {({ errors, handleChange, handleSubmit, handleBlur, touched, values, isValid, dirty, setFieldValue }) => {
        const isNewPlan = !hraPlan?.id
        const isFormValid = isValid && dirty
        const isPlanValid = hraPlan?.id && isValid

        const isValidAutoPayDate = values.hraStartDate ? !isDateWithinRange(values.hraStartDate, 35) : null

        const disabled = !(
          (isNewPlan &&
            isFormValid &&
            ((!isValidAutoPayDate && values?.isAutoPay === false) || isValidAutoPayDate) &&
            participatingEmployees <= eligibleEmployees &&
            values.hraStartDate?.toString !== null) ||
          isPlanValid
        )

        const showStarterPlan =
          eligibleEmployees > 0 &&
          participatingEmployees > 0 &&
          eligibleEmployees >= participatingEmployees &&
          values.isAutoPay === false

        const showGrowthPlan =
          eligibleEmployees > 0 &&
          participatingEmployees > 0 &&
          eligibleEmployees >= participatingEmployees &&
          values.isAutoPay

        const showAutoPay =
          participatingEmployees >= MAXIMUM_PARTICIPATING_EMPLOYEES && eligibleEmployees >= participatingEmployees

        return (
          <>
            <Stepper steps={stepData} activeStepIndex={1} />
            <form noValidate onSubmit={handleSubmit} data-qa="plan-setup-form">
              {errors?.submit && (
                <Alert mt={2} mb={1} severity="warning" data-qa="error-banner-plan-setup-form">
                  {errors?.submit}
                </Alert>
              )}
              <Grid container spacing={4} mt={5}>
                <Grid item xs={12} mb={5}>
                  <Typography variant="h1" data-qa="plan-setup-title">
                    Plan Setup
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" data-qa="plan-setup-help">
                    We promise this will be easy-- just a few minutes. Let's get started!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" data-qa="plan-setup-start-date">
                    When would you like your HRA to start?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <SelectField
                    data-qa="hra-start-date-dropdown"
                    name="hraStartDate"
                    data={getNextSixMonthsStartDates()}
                    type="date"
                    label="Date"
                    value={String(values.hraStartDate)}
                    placeholder="Please select"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  {touched.hraStartDate && errors.hraStartDate && !values.hraStartDate}
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Typography variant="h5" data-qa="plan-setup-eligible-employees-title">
                      How many benefit eligible employees do you have?
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ClampedTextField
                      data-qa="eligible-employees-text-field"
                      type="text"
                      name="eligibleEmployees"
                      label="Amount of employees"
                      required
                      error={Boolean(touched.eligibleEmployees && errors.eligibleEmployees)}
                      fullWidth
                      helperText={touched.eligibleEmployees && errors.eligibleEmployees}
                      onBlur={handleBlur}
                      onChange={e => {
                        setEligibleEmployees(parseInt(e.target.value))
                        setShowAffordabilityModal(parseInt(e.target.value) >= MAXIMUM_ELIGIBLE_EMPLOYEES)
                        handleChange(e)
                        if (showAutoPay) {
                          setFieldValue("isAutoPay", true)
                        }
                      }}
                      sx={{ my: 3 }}
                      value={values.eligibleEmployees}
                    />
                  </Grid>
                  {eligibleEmployees >= MAXIMUM_ELIGIBLE_EMPLOYEES && (
                    <DashboardReturnConfirmation
                      onClose={function (): void {
                        setFieldValue("eligibleEmployees", "")
                        setShowAffordabilityModal(false)
                      }}
                      isOpen={showAffordabilityModal}
                      onConfirm={function (): void {
                        setFieldValue("eligibleEmployees", "")
                        setShowAffordabilityModal(false)
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Typography variant="h5" data-qa="plan-setup-participating-employees-title">
                      How many participating employees do you have?
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ClampedTextField
                      data-qa="participating-employees-text-field"
                      type="text"
                      name="participatingEmployees"
                      label="Amount of employees"
                      required
                      value={values.participatingEmployees}
                      error={Boolean(touched.participatingEmployees && errors.participatingEmployees)}
                      fullWidth
                      helperText={touched.participatingEmployees && errors.participatingEmployees}
                      onBlur={handleBlur}
                      onChange={e => {
                        setParticipatingEmployees(parseInt(e.target.value))
                        handleChange(e)
                        if (showAutoPay) {
                          setFieldValue("isAutoPay", true)
                        }
                      }}
                      sx={{ my: 3 }}
                    />
                  </Grid>
                </Grid>
                {showAutoPay && (
                  <Grid item xs={12}>
                    <Grid container spacing={4} mt={5}>
                      <Grid item xs={12}>
                        <Typography variant="h5" data-qa="plan-setup-autopay">
                          Do you want to add AutoPay to your plan?
                        </Typography>
                        <Typography variant="body1" textAlign="left" pb="1rem">
                          AutoPay payment solution simulates the same cashflow experience as a traditional group plan –
                          employers pay monthly premiums directly to insurance carriers, then withhold any employee
                          portion via payroll as needed. AutoPay enables the convenience of a group plan with the
                          personalization of the individual market.{" "}
                          <Link target="_blank" href={EXTERNAL_LINKS.AUTOPAY}>
                            Click here
                          </Link>{" "}
                          to learn more about AutoPay.
                        </Typography>
                        <Typography variant="body1" textAlign="left" pb="1rem">
                          Important: If you want to use AutoPay, you must complete your AutoPay application and bank
                          set-up at least <b>35 days</b> prior to your HRA plan start date. If today, {currentDate}, is
                          less than <b>35 days</b> before your selected start date, you can:
                        </Typography>
                        <Typography variant="body1" textAlign="left" pb="1rem" pl="2rem">
                          1. Choose a later HRA plan start date
                        </Typography>
                        <Typography variant="body1" textAlign="left" pb="1rem" pl="2rem">
                          2. Select “No” and move forward with a reimbursement model
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <RadioGroupCard
                          name="isAutoPay"
                          formName="plan-setup"
                          value={values.isAutoPay}
                          handleChange={setFieldValue}
                          elements={[
                            {
                              title: "Yes",
                              value: true,
                            },
                            {
                              title: "No",
                              value: false,
                            },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {showStarterPlan && (
                  <PackageCardInfo
                    clarificationTitle="Starter Plan Cost"
                    clarificationText="With this selection you qualify for our Starter Plan, you will be charged $20 per employee per month (PEPM) (all non-waived employees) + $40 monthly platform fee."
                    planTitle="Starter Plan Clarification"
                    planDescription="Easy & cost-effective solution for small employers new-to-benefits, up to 49 employees."
                    planOptions=""
                    chipLabel="STARTER"
                    chipColor=""
                    chipBackgroundColor="colors.lightGrayText"
                  />
                )}

                {showGrowthPlan && (
                  <PackageCardInfo
                    clarificationTitle="Growth Plan Cost"
                    clarificationText="With this selection you qualify for our Growth Plan,  you will be charged $30 per employee per month (PEPM) (all
                      non-waived employees) + $40 monthly platform fee. This plan includes a minimum billing of 20 PEPM."
                    planTitle="Growth Plan Clarification"
                    planDescription="Health benefits with a payment solution and integrations that serve growing companies up to 49 employees."
                    planOptions=""
                    chipLabel="GROWTH"
                    chipColor="#FFF"
                    chipBackgroundColor={takeCommandSecondary.dark}
                  />
                )}

                <FlowNavigationButtons
                  handleBack={() => {
                    stepData.forEach(step => {
                      step.isDiy = true
                    })
                    navigate("/adp/create-company/account")
                  }}
                  type="submit"
                  disabled={disabled}
                  isSubmitting={isLoading}
                />
              </Grid>
            </form>
          </>
        )
      }}
    </Formik>
  )
}
