import { AppContentWrapper } from "@/components/AppContentWrapper"
import { Footer } from "@/components/Footer"
import { Navbar } from "@/components/Navbar/Navbar"
import { RootWrapper } from "@/components/RootWrapper"
import { AuthGuard } from "@/features/Auth/guards/AuthGuard"
import { BenefitsElectionGuard } from "@/features/Auth/guards/BenefitsElectionGuard"
import { Toolbar } from "@mui/material"
import { Outlet } from "react-router-dom"

export const EmployeeWelcomeLayout = () => (
  <AuthGuard>
    <BenefitsElectionGuard>
      <RootWrapper>
        <AppContentWrapper>
          <Navbar />
          <Toolbar />
          <Outlet />
          <Footer />
        </AppContentWrapper>
      </RootWrapper>
    </BenefitsElectionGuard>
  </AuthGuard>
)
